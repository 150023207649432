import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import Terms from '../pages/built-in/others/Terms'
import Error404Modern from '../pages/error/404-modern'
import Login from '../pages/auth/Login'
import Register from '../pages/auth/Register'
import Success from '../pages/auth/Success'
import Layout from '../layout/Index'
import LayoutWithSearch from '../layout/Index-withSearch'
import LayoutNoSidebar from '../layout/Index-nosidebar'
import Dashboard from '../pages/Dashboard'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import { setLanguageInURL } from '../utils/Utils'
import ImporterCompanies from '../pages/ImporterCompanies'
import PriceAnalysis from '../pages/PriceAnalysis'
import ProductAnalysis from '../pages/ProductAnalysis'
import MarketChanges from '../pages/MarketChanges'
import MarketOverview from '../pages/MarketOverview'
import { useGlobalStoreActions, useGlobalStoreData } from '../store/globalStore'
import McDataServices from '../services/McDataServices'
import { getUserDetailsAndProcess } from '../services/DataServiceMethods'
import SummaryView from '../pages/SummaryView'
import ForgotPassword from '../pages/auth/ForgotPassword'
import NewPassword from '../pages/auth/NewPassword'
import CustomsDuties from '../pages/CustomsDuties'
import PdfContainer from '../pages/PdfContainer'
import withAuth from '../hoc/withAuth'
import { isReferrerAllowed } from '../pages/mc-components/McPageHelpers'

// Mail Template imports
import MailTemplateLayout from '../mailTemplate/src/layouts/MailTemplateLayout'
import { getBooleanUserRolesByUser } from '../mailTemplate/src/utils/helpers'
import Campaigns from '../mailTemplate/src/components/atomic/pages/Campaigns'
import Users from '../mailTemplate/src/components/atomic/pages/Users'
import Wizard from '../mailTemplate/src/components/atomic/pages/Wizard'
import Mailbox from '../mailTemplate/src/components/atomic/pages/Mailbox'
// import CampaignReport from '../mailTemplate/src/components/atomic/pages/CampaignReport'

const LayoutWithAuth = withAuth(LayoutWithSearch)
const MailLayoutWithAuth = withAuth(MailTemplateLayout)

const Pages = ({ history }) => {
  const location = useLocation()
  const { i18n } = useTranslation()
  const languageGSD = useGlobalStoreData.language()
  const versionGSD = useGlobalStoreData.version()
  const userGSD = useGlobalStoreData.user()
  const {
    setLoggedInStatus,
    setAppVersion,
    setLanguage,
    setUserData,
    setHsCodes
  } = useGlobalStoreActions()

  const userRoles = getBooleanUserRolesByUser(userGSD)

  const [tokenChecked, setTokenChecked] = useState(false)

  const screenScrollFixer = () => window.scrollTo(0, 0)
  const getAppVersion = () => {
    if (versionGSD === '') McDataServices.getVersion().then(version => setAppVersion(version))
    return () => {}
  }

  const tokenChecker = () => {
    setLanguageInURL(localStorage.getItem('lang'), true)

    if (isReferrerAllowed()) {
      McDataServices.logout().finally(() => {
        localStorage.setItem('username', '')
        getUserDetailsAndProcess(true, userGSD, setUserData)
        setTokenChecked(true)
      })
    } else {
      McDataServices.checkToken()
        .then(result => {
          getUserDetailsAndProcess(!!result, userGSD, setUserData)
          setLoggedInStatus(!!result)
        })
        .finally(() => {
          setTokenChecked(true)
        })
    }
    return () => {}
  }
  const urlLanguageFixer = () => {
    if (languageGSD !== localStorage.getItem('lang')) setLanguage(localStorage.getItem('lang'))
  }
  const languageFixer = () => {
    const selectedLang = localStorage.getItem('lang')
    i18n.changeLanguage(selectedLang)
    setLanguageInURL(selectedLang, false)
  }
  const pathnameFixer = () => {
    if (location.pathname === '/tr' || location.pathname === '/en') {
      let predefinedSearchParams = new URL(window.location.href).searchParams
      predefinedSearchParams = predefinedSearchParams ? '?' + predefinedSearchParams.toString() : ''
      history?.push('/' + predefinedSearchParams)
    }
  }

  const getAndStoreHsCodes = () => {
    McDataServices.getHsCodes().then((res) => {
      setHsCodes(res)
    })
  }

  useLayoutEffect(screenScrollFixer, [location])
  useEffect(tokenChecker, [])
  useEffect(getAppVersion, [])
  useEffect(() => {
    languageFixer()
    getAndStoreHsCodes()
  }, [languageGSD])
  useEffect(urlLanguageFixer, [localStorage.getItem('lang')])
  useEffect(pathnameFixer, [location.pathname])

  return (
    <Routes>
      <Route path={`${process.env.PUBLIC_URL}`}>
        <Route element={<LayoutWithAuth tokenChecked={tokenChecked}/>}>
          <Route index element={<Dashboard/>}></Route>
          <Route path=":lang" element={<Dashboard/>}></Route>
          <Route path=":lang/importer-companies" element={<ImporterCompanies/>}></Route>
          <Route path=":lang/price-analysis" element={<PriceAnalysis/>}></Route>
          <Route path=":lang/product-analysis" element={<ProductAnalysis/>}></Route>
          <Route path=":lang/market-changes" element={<MarketChanges/>}></Route>
          <Route path=":lang/market-overview" element={<MarketOverview/>}></Route>
          <Route path=":lang/summary-tables" element={<SummaryView/>}></Route>
          <Route path=":lang/custom-duties" element={<CustomsDuties/>}></Route>
          <Route path=":lang/pdf" element={<PdfContainer/>}></Route>
        </Route>
      </Route>

      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar/>}>
        <Route path=":lang/success" element={<Success/>}></Route>
        <Route path=":lang/reset" element={<ForgotPassword/>}></Route>
        <Route path=":lang/new-password" element={<NewPassword/>}></Route>
        <Route path=":lang/register" element={<Register/>}></Route>
        <Route path=":lang/login" element={<Login/>}></Route>
        <Route path="login" element={<Login/>}></Route>
        <Route path="*" element={<Error404Modern/>}></Route>
      </Route>

      <Route path={`${process.env.PUBLIC_URL}/:lang/register-external`} element={<Register/>}></Route>

      <Route path={`${process.env.PUBLIC_URL}`} element={<Layout/>}>
        <Route path=":lang/pages">
          <Route path="terms" element={<Terms/>}></Route>
        </Route>
      </Route>

      <Route path={`${process.env.PUBLIC_URL}`} element={<MailLayoutWithAuth title={t('mailTemplate.pageTitle')}/>}>
        {
          (userRoles.B2B) &&
          <Route path=":lang/mailing">
            <Route index element={<Campaigns userGSD={userGSD}/>}/>
            {userRoles.B2B_PERSONNEL && <Route path="users" element={<Users userGSD={userGSD}/>}/>}
            <Route path="wizard" element={<Wizard/>}/>
            <Route path="mailbox" element={<Mailbox/>}/>
            {/* <Route path="campaign-report" element={<CampaignReport/>}/> */}
          </Route>
        }
      </Route>
    </Routes>
  )
}
export default Pages
