import React, { useEffect, useState, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

// MUI Imports
import Typography from '@mui/material/Typography'
import TablePagination from '@mui/material/TablePagination'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import { Button, CircularProgress, IconButton } from '@mui/material'
import { ExpandMore, ExpandLess, PlaylistAdd } from '@mui/icons-material'

// Third-party Imports
import classnames from 'classnames'
import { rankItem } from '@tanstack/match-sorter-utils'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel
} from '@tanstack/react-table'

// Component Imports
import TablePaginationComponent from '../molecules/TablePaginationComponent'
import CustomTextField from '../atoms/CustomTextField'
import DebouncedInput from '../atoms/DebouncedInput'
import AdditionalEmailModal from '../molecules/AdditionalEmailModal'

// Style Imports
import tableStyles from '../../styles/table.module.scss'

const httpPrefixer = (url) => {
  return (url?.indexOf('://') === -1) ? `http://${url}` : url
}

const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

// Column Definitions
const columnHelper = createColumnHelper()

const FirmList = (props) => {
  const {
    data,
    rowSelection,
    setRowSelection,
    setSelectedFirms,
    fetching,
    handleNext,
    additionalEmails,
    setAdditionalEmails
  } = props
  const { t } = useTranslation()

  const [globalFilter, setGlobalFilter] = useState('')
  const [additionalEmailsModalOpen, setAdditionalEmailsModalOpen] = useState(false)

  const columns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler()
            }}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler()
            }}
          />
        )
      },
      columnHelper.accessor('name', {
        header: t('mailTemplate.wizard.firmList.firmName'),
        cell: ({ row }) => (
          <div className='flex items-center gap-4'>
            <div className='flex flex-col'>
              <Typography color='text.primary' className='font-medium'>
                {row.original.name}
              </Typography>
            </div>
          </div>
        )
      }),
      columnHelper.accessor('web', {
        header: t('mailTemplate.wizard.firmList.website'),
        cell: ({ row }) => (
          <div className='flex items-center gap-4'>
            <div className='flex flex-col' onClick={(e) => {
              e.stopPropagation()
            }}>
              <a href={httpPrefixer(row.original.web)} target="_blank" rel="noreferrer">
                <Typography color='text.primary' className='font-medium'>
                  {row.original.web}
                </Typography>
              </a>
            </div>
          </div>
        )
      })
    ],
    []
  )

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    state: {
      rowSelection,
      globalFilter
    },
    initialState: {
      pagination: {
        pageSize: 10
      }
    },
    enableRowSelection: true, // enable row selection for all rows
    // enableRowSelection: row => row.original.age > 18, // or enable row selection conditionally per row
    globalFilterFn: fuzzyFilter,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  })

  useEffect(() => {
    const selected = table.getSelectedRowModel().rows.map(({ original }) => original)
    setSelectedFirms(selected)
  }, [rowSelection])

  return (
    <>
      <div className='flex justify-between flex-col items-start md:flex-row md:items-center p-6 border-bs gap-4'>
        <IconButton
          onClick={() => { setAdditionalEmailsModalOpen(true) }}
          color='primary'
        >
          <PlaylistAdd/>
        </IconButton>
        <div className='flex flex-col sm:flex-row is-full sm:is-auto items-start sm:items-center gap-4'>
          <CustomTextField
            select
            value={table.getState().pagination.pageSize}
            onChange={e => table.setPageSize(Number(e.target.value))}
            className='is-[70px]'
          >
            <MenuItem value='10'>10</MenuItem>
            <MenuItem value='25'>25</MenuItem>
            <MenuItem value='50'>50</MenuItem>
          </CustomTextField>
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={value => setGlobalFilter(String(value))}
            placeholder={t('mailTemplate.wizard.firmList.search')}
            className='is-full sm:is-auto'
          />
          <Button variant='contained' onClick={handleNext}>
            {t('mailTemplate.wizard.firmList.nextBtn')}
          </Button>
        </div>
      </div>
      <div className='overflow-x-auto'>
        <table className={tableStyles.table}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : (
                      <>
                        <div
                          className={classnames({
                            'flex items-center': header.column.getIsSorted(),
                            'cursor-pointer select-none': header.column.getCanSort()
                          })}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <ExpandLess/>,
                            desc: <ExpandMore/>
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      </>
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {table.getFilteredRowModel().rows.length === 0
            ? (
            <tbody>
              <tr>
                <td colSpan={table.getVisibleFlatColumns().length} className='text-center'>
                  {fetching ? <CircularProgress/> : t('mailTemplate.wizard.firmList.noData')}
                </td>
              </tr>
            </tbody>
              )
            : (
            <tbody>
              {table
                .getRowModel()
                .rows.slice(0, table.getState().pagination.pageSize)
                .map(row => {
                  return (
                    <tr
                      key={row.id}
                      className={classnames({ selected: row.getIsSelected() })}
                      style={{ cursor: 'pointer' }}
                      onClick={row.getToggleSelectedHandler()}
                    >
                        {row.getVisibleCells().map(cell => (
                          <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                        ))}
                    </tr>
                  )
                })}
            </tbody>
              )}
        </table>
      </div>
      <TablePagination
        component={() =>
          <TablePaginationComponent
            table={table}
            infoText1={t('mailTemplate.wizard.firmList.tableInfo_1')}
            infoText2={t('mailTemplate.wizard.firmList.tableInfo_2')}
          />
        }
        count={table.getFilteredRowModel().rows.length}
        rowsPerPage={table.getState().pagination.pageSize}
        page={table.getState().pagination.pageIndex}
        onPageChange={(_, page) => {
          table.setPageIndex(page)
        }}
      />
      <AdditionalEmailModal
        open={additionalEmailsModalOpen}
        setOpen={setAdditionalEmailsModalOpen}
        additionalEmails={additionalEmails}
        setAdditionalEmails={setAdditionalEmails}
      />
    </>
  )
}

export default FirmList
