import axios from 'axios'

export const IS_PROD = process.env.NODE_ENV === 'production'

let prodBackend = 'https://app.marketcalculus.ai:9090'
let acao = 'https://app.marketcalculus.ai'
if (window.location.host.startsWith('test.marketcalculus.ai')) {
  prodBackend = 'https://test.marketcalculus.ai:9091'
  acao = 'https://test.marketcalculus.ai:9091'
}
export const BASE_URL = IS_PROD ? prodBackend : 'http://127.0.0.1:9090'
export const HOMEPAGE_URL = 'https://marketcalculus.ai'

const createAxiosInstance = () => {
  const instance = axios.create({
    baseURL: BASE_URL + '/b2b/api/v1',
    withCredentials: true
  })

  instance.interceptors.request.use(config => {
    const lang = localStorage.getItem('lang') || 'en'
    config.headers = {
      ...config.headers,
      'Access-Control-Allow-Origin': acao,
      'Content-type': 'application/json',
      dil: lang
    }
    return config
  })

  return instance
}

const B2BAPI = createAxiosInstance()
export default B2BAPI
