import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

// MUI Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import MuiStepper from '@mui/material/Stepper'
import styled from '@mui/material/styles/styled'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize'
import PreviewIcon from '@mui/icons-material/Preview'

// Component Imports
import StepperWrapper from '../atoms/StepperWrapper'
import FirmList from '../organisms/FirmList'
import TemplateSelection from '../organisms/TemplateSelection'
import Preview from '../organisms/Preview'

import { getFirms, getTemplates, sendEmail } from '../../../services/B2BServices'
import { getUrlParamsObj } from '../../../services/urlServices'
import { useGlobalStoreData } from '../../../../../store/globalStore'

// Styled Components
const Stepper = styled(MuiStepper)(({ theme }) => ({
  justifyContent: 'center',
  '& .MuiStep-root': {
    '& + i': {
      display: 'none',
      color: 'var(--mui-palette-text-secondary) !important'
    },
    '& .MuiStepLabel-label': {
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      paddingBlock: theme.spacing(5),
      svg: {
        marginInline: theme.spacing(1.5),
        fill: 'var(--mui-palette-text-primary)'
      },
      '&.Mui-active, &.Mui-completed': {
        '& .MuiTypography-root': {
          color: 'var(--mui-palette-primary-main)'
        },
        '& svg': {
          fill: 'var(--mui-palette-primary-main)'
        }
      }
    },
    '&.Mui-completed + i': {
      color: 'var(--mui-palette-primary-main) !important'
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: 0,
      '& + svg': {
        display: 'block'
      },
      '& .MuiStepLabel-label': {
        display: 'block'
      }
    }
  }
}))

const getStepContent = (
  step,
  handleNext,
  firms = [],
  templates = [],
  rowSelection,
  setRowSelection,
  setSelectedFirms,
  fetching,
  selectedTemplate,
  setSelectedTemplate,
  onSubmit,
  b2bAccountId,
  getTemplatesWithSelected,
  nextLoading,
  additionalEmails,
  setAdditionalEmails
) => {
  switch (step) {
    case 0:
      return <FirmList
        handleNext={handleNext}
        data={firms}
        setSelectedFirms={setSelectedFirms}
        fetching={fetching}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        additionalEmails={additionalEmails}
        setAdditionalEmails={setAdditionalEmails}
      />
    case 1:
      return <TemplateSelection
        handleNext={handleNext}
        templates={templates}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
        b2bAccountId={b2bAccountId}
        getTemplatesWithSelected={getTemplatesWithSelected}
      />
    case 2:
      return <Preview
        selectedTemplate={selectedTemplate}
        onSubmit={onSubmit}
        nextLoading={nextLoading}
      />
    default:
      return null
  }
}

const Wizard = () => {
  const { t } = useTranslation()
  const queryParams = getUrlParamsObj()
  const navigate = useNavigate()

  const [activeStep, setActiveStep] = useState(0)
  const [rowSelection, setRowSelection] = useState({})// FirmList selection state
  const [firms, setFirms] = useState([])
  const [selectedFirms, setSelectedFirms] = useState([])
  const [fetching, setFetching] = useState(true)
  const [nextLoading, setNextLoading] = useState(false)
  const [templates, setTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [additionalEmails, setAdditionalEmails] = useState([])

  const languageGSD = useGlobalStoreData.language()

  const steps = [
    {
      title: t('mailTemplate.wizard.firmSelection'),
      icon: AddBusinessIcon
    },
    {
      title: t('mailTemplate.wizard.templateSelection'),
      icon: DashboardCustomizeIcon
    },
    {
      title: t('mailTemplate.wizard.preview'),
      icon: PreviewIcon
    }
  ]

  const handleNext = (step = null) => {
    step >= 0 ? setActiveStep(step) : setActiveStep(activeStep + 1)
  }

  const onSubmit = (formVal) => {
    if ((!selectedFirms?.length && !additionalEmails?.length) || !selectedTemplate) {
      const toastError = selectedTemplate ? t('mailTemplate.wizard.firmError') : t('mailTemplate.wizard.templateError')

      toast.error(toastError, {
        position: 'top-right',
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false
      })
    } else {
      setNextLoading(true)

      const additionalFirmList = additionalEmails?.length
        ? additionalEmails.map((item) => ({
          emailList: [item],
          name: null,
          web: null
        }))
        : []

      const payload = {
        b2bCampaignId: queryParams.b2bCampaignId,
        templateId: selectedTemplate.id,
        subject: formVal.mailSubject,
        templateParameters: {},
        firmList: selectedFirms,
        additionalFirmList
      }

      sendEmail(payload)
        .then((res) => {
          toast.success(t('mailTemplate.wizard.emailSuccess'), {
            position: 'top-right',
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false
          })

          const targetURL = `${process.env.PUBLIC_URL}/${languageGSD}/mailing?b2bAccountId=${queryParams.b2bAccountId}`
          navigate(targetURL)
        })
        .catch((err) => {
          toast.error(err || t('mailTemplate.wizard.emailError'), {
            position: 'top-right',
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false
          })
        })
        .finally(() => {
          setNextLoading(false)
        })
    }
  }

  useEffect(() => {
    queryParams?.b2bCampaignId && getFirms({ b2bCampaignId: queryParams.b2bCampaignId }).then((res) => {
      setFirms(res.data)
    }).finally(() => {
      setFetching(false)
    })
  }, [])

  const getTemplatesWithSelected = (selectedTemplateId = null) => {
    queryParams?.b2bAccountId && getTemplates({ b2bAccountId: queryParams.b2bAccountId }).then((res) => {
      setTemplates(res.data)

      const selected = selectedTemplateId ? res.data?.find((x) => x.id === selectedTemplateId) : res.data?.[0]
      setSelectedTemplate(selected)
    })
  }

  useEffect(() => {
    getTemplatesWithSelected()
  }, [languageGSD])

  const queryParamsError = (queryParams, b2bAccountIdError, b2bCampaignIdError) => {
    const b2bAccountId = queryParams?.b2bAccountId
    const b2bCampaignId = queryParams?.b2bCampaignId

    if (b2bAccountId && b2bCampaignId) {
      return false
    } else if (b2bCampaignId) {
      return <div>{b2bAccountIdError}</div>
    } else {
      return <div>{b2bCampaignIdError}</div>
    }
  }

  return (
    queryParamsError(queryParams, t('mailTemplate.wizard.b2bAccountIdError'), t('mailTemplate.wizard.b2bCampaignIdError')) ||
    <Card>
      <CardContent>
        <StepperWrapper>
          <Stepper
            activeStep={activeStep}
            connector={
              <ArrowForwardIosIcon className='mli-12 hidden md:block text-xl text-textDisabled'/>
            }
          >
            {steps.map((step, index) => {
              return (
                <Step key={index} onClick={() => handleNext(index)}>
                  <StepLabel icon={<></>} className='text-center'>
                    <step.icon sx={{ fontSize: 50 }}/>
                    <Typography className='step-title'>{step.title}</Typography>
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </StepperWrapper>
      </CardContent>
      <Divider />

      {getStepContent(
        activeStep,
        handleNext,
        firms,
        templates,
        rowSelection,
        setRowSelection,
        setSelectedFirms,
        fetching,
        selectedTemplate,
        setSelectedTemplate,
        onSubmit,
        queryParams.b2bAccountId,
        getTemplatesWithSelected,
        nextLoading,
        additionalEmails,
        setAdditionalEmails
      )
      }
    </Card>
  )
}

export default Wizard
