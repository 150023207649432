import React, { useCallback, useEffect, useState } from 'react'
import { Button, CardTitle, Col, FormGroup, Label, Row } from 'reactstrap'
import { Icon, PreviewAltCard, RSelect } from '../../components/Component'
import McDataServices from '../../services/McDataServices'
import { useTranslation } from 'react-i18next'
import { useSDSisVisible, useSDSsearchParams, useSDSselectBoxData, useSearchDataStoreActions } from '../../store/searchDataStore'
import { useGlobalStoreData } from '../../store/globalStore'
import { useDataStoreActions } from '../../store/dataStore'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'
import CONSTANTS from '../../utils/Constants'
import { useEndpointStatusActions, useEndpointStatusData } from '../../store/endpointStatus'
import ErrorModal from '../../components/error-modal/error-modal'
import { useRawStoreActions } from '../../store/rawStore'
import { getUrlParamsObj } from '../../services/urlServices'
import { HOMEPAGE_URL } from '../../services/api-common'
import { LANGUAGES } from '../../utils/Utils'

const showErrorModal = (fireErrorModal, errorMessage, t, searchParams, allowedCountries, selectedCountryCode, languageGSD) => {
  switch (errorMessage?.ruleType) {
    case 'HS_CODE_COUNT':
      fireErrorModal(
        t('error_hs_code_count_limit_text', { limit: errorMessage.limit }),
        errorMessage.customMessage.split(',').map((obj, index) => <span key={index}>{McPageHelpers.findCountryName(obj)}<br/></span>)
      )
      break
    case 'HS_CODE_SET':
      fireErrorModal(
        t('error_hs_code_set_text', { hsCode: searchParams.hsCode }),
        errorMessage.customMessage.split(',').map((obj, index) => <span key={index}>{McPageHelpers.findCountryName(obj)}<br/></span>)
      )
      break
    case 'SEARCH_COUNTRY_SET':
      fireErrorModal(
        t('error_search_country_set_text', { exporter: McPageHelpers.findCountryName(selectedCountryCode) }),
        allowedCountries && allowedCountries.map((obj, index) => <span key={index}>{McPageHelpers.findCountryName(obj)}<br/></span>)
      )
      break
    case 'MARKET_REPORT_TOTAL_SEARCH_COUNT':
      fireErrorModal(
        t('error_market_report_total_search_limit_title', { limit: errorMessage.limit }),
        t('error_market_report_total_search_limit_text', { currentValue: errorMessage.currentValue })
      )
      break
    case 'MARKET_REPORT_DAILY_SEARCH_COUNT':
      fireErrorModal(
        t('error_daily_search_limit_title', { limit: Math.round(errorMessage.limit / 10) }),
        t('error_daily_search_limit_text', { currentValue: Math.round(errorMessage.currentValue / 10) })
      )
      break
    case 'MARKET_REPORT_ANONYMOUS_DAILY_SEARCH_COUNT':
      fireErrorModal(
        t('error_anonymous_daily_search_limit_title', { limit: Math.round(errorMessage.limit / 10) }),
        t('error_anonymous_daily_search_limit_text', { currentValue: Math.round(errorMessage.currentValue / 10) }),
        `${HOMEPAGE_URL}/${languageGSD === LANGUAGES.TURKISH ? 'tr/uyeol' : 'en/register'}/`
      )
      break
    case 'MARKET_REPORT_EXCEL_DOWNLOAD_COUNT':
      fireErrorModal(
        t('error_excel_download_count_limit_title', { limit: errorMessage.limit }),
        t('error_excel_download_count_limit_text', { currentValue: errorMessage.currentValue })
      )
      break
  }
}

const SearchSidebar = () => {
  const { t } = useTranslation()
  const isLoggedInGSD = useGlobalStoreData.isLoggedIn()
  const userGSD = useGlobalStoreData.user()
  const languageGSD = useGlobalStoreData.language()
  const localeGSD = useGlobalStoreData.locale()
  const errorMessage = useEndpointStatusData.errorMessage()
  const { setEndpointStatus } = useEndpointStatusActions()
  const [errorModalMessage, setErrorModalMessage] = useState()
  const [errorModalTitle, setErrorModalTitle] = useState()
  const [localSearchParams, setLocalSearchParams] = useState({})
  const setLocalSearchParamsByKey = (key, val) => {
    setLocalSearchParams((prevState) => ({
      ...prevState, [key]: val
    }))
  }
  const [errorModalHref, setErrorModalHref] = useState(null)

  useEffect(() => {
    const predefinedUrlParams = getUrlParamsObj()
    setLocalSearchParams((prevState) => ({
      ...prevState,
      ...predefinedUrlParams
    }))
  }, [])

  const searchParams = useSDSsearchParams()
  const selectBoxData = useSDSselectBoxData()
  const isVisible = useSDSisVisible()
  const isSearchBtnEnabled = (!!localSearchParams.countryToExport && !!localSearchParams.yourCountry && !!localSearchParams.hsCode)// useSDSisSearchBtnEnabled()
  const {
    setSearchParamsWithPrev,
    startSearching,
    setSelectBoxData,
    toggleVisibility
  } = useSearchDataStoreActions()

  const { resetData } = useDataStoreActions()

  const { resetRawStore } = useRawStoreActions()

  const initState = {
    showHsCodeOptions: false,
    showCountryExportOptions: false,
    showYourCountryOptions: false,
    allowedCountry: null
  }

  const [state, setState] = useState(initState)
  const [loading, setLoading] = useState(true)
  const searchCountrySet = userGSD?.rules?.find(item => item.type === 'SEARCH_COUNTRY_SET')
  const allowedCountries = searchCountrySet?.parameters?.allowedSearchCountries
  const isLoadingUserInfo = !userGSD?.rules ?? true
  const [modalFail, setModalFail] = useState(false)
  const [isHsCodeEmpty, setIsHsCodeEmpty] = useState(false)

  useEffect(() => {
    if (typeof allowedCountries === 'string' && allowedCountries.length === 2) {
      setLocalSearchParamsByKey('countryToExport', allowedCountries)
      setState(prevState => ({
        ...prevState,
        selectedCountry: state.allowedCountry
      }))
      return setState((prevState) =>
        ({
          ...prevState,
          allowedCountry: {
            label: McPageHelpers.findCountryName(allowedCountries),
            value: allowedCountries
          }
        })
      )
    } else if (Array.isArray(allowedCountries) && allowedCountries.length !== 1) {
      return setState((prevState) =>
        ({
          ...prevState,
          allowedCountry: null
        })
      )
    } else {
      return setState((prevState) =>
        ({
          ...prevState,
          allowedCountry: null
        })
      )
    }
  }, [userGSD, allowedCountries])

  useEffect(() => {
    if (!isLoadingUserInfo) {
      setLoading(false)
    }
  }, [isLoadingUserInfo])

  useEffect(() => {
    if (isLoggedInGSD === true) {
      McDataServices.getHsCodes().then(result => setSelectBoxData('hsCode', result))
      McDataServices.getCountries().then(result => {
        result.search_countries.find(country => country.countryCode === 'US').abbreviation = CONSTANTS.USA[languageGSD]
        result.user_countries.find(country => country.countryCode === 'US').abbreviation = CONSTANTS.USA[languageGSD]

        setSelectBoxData('coverageData', result.search_countries)
        setSelectBoxData('countries', result.user_countries)
      })
    }
  }, [languageGSD])

  const handlePressEnterKey = (event, elementForFocus, input = false) => {
    if (event.keyCode === 13) {
      const button = document.getElementById('searchBtn')
      if (elementForFocus === 'searchBtn') {
        button.focus()
      } else if (!input) {
        document.getElementById(elementForFocus).getElementsByTagName('input')[0].focus()
      } else { document.getElementById(elementForFocus).focus() }
    }
  }

  const fireErrorModal = (title, message, href = null) => {
    setErrorModalHref(href)
    setErrorModalTitle(title)
    setErrorModalMessage(message)
    setModalFail(true)
  }

  useEffect(() => {
    showErrorModal(
      fireErrorModal,
      errorMessage,
      t,
      searchParams,
      allowedCountries,
      state?.selectedCountry?.countryCode,
      languageGSD
    )
  }, [errorMessage])

  const handleSearch = () => {
    setSearchParamsWithPrev(localSearchParams)
    // setSearchParams('countryToExport', localSearchParams.countryToExport)
    // setSearchParams('hsCode', localSearchParams.hsCode)
    // setSearchParams('countryToExportName', localSearchParams.countryToExportName)
    // setSearchParams('dataCoverage', localSearchParams.dataCoverage)
    // setSearchParams('productDesc', localSearchParams.productDesc)
    // setSearchParams('yourCountry', localSearchParams.yourCountry)

    setEndpointStatus('errorMessage')
    toggleVisibility()
    resetData()
    resetRawStore()
    startSearching()
  }

  const stateParamChanger = useCallback((paramName, typedOption) => {
    setState((prevState) => ({
      ...prevState, [paramName]: typedOption?.length >= 3
    }))
  }, [setState])

  const handleHsCodeChange = (typedOption) => stateParamChanger('showHsCodeOptions', typedOption)
  const handleExportChange = (typedOption) => stateParamChanger('showCountryExportOptions', typedOption)
  const handleYourCountryChange = (typedOption) => stateParamChanger('showYourCountryOptions', typedOption)
  if (loading) return
  return (<div id="searchFilterArea" className={isVisible ? 'show' : 'hide'}>
    <div id="searchFilterAreaToggleBtn" className="btn btn-secondary d-none" onClick={toggleVisibility}>
      {isVisible
        ? <Icon style={{ color: '#816bff' }} name="arrow-left-fill-c"/>
        : <Icon style={{ color: '#816bff' }}
                name="arrow-right-fill-c"/>}
    </div>
    <PreviewAltCard className="card">
      <CardTitle tag="h6">{t('searching_parameters')}</CardTitle>
      <Row>
        <Col sm="12">
          <FormGroup>
            <Label htmlFor="hsCode" className="form-label">
              {t('hs_code')}
            </Label>
            <div className="form-control-wrap">
              <RSelect
                id="hsCode"
                options={state.showHsCodeOptions ? selectBoxData.hsCode : []}
                defaultInputValue={new URL(window.location.href).searchParams.get('hsCode') ?? ''}
                isLoading={!selectBoxData.hsCode?.length}
                isMulti
                formatOptionLabel={({ value, label }) => {
                  if (localSearchParams.hsCode?.includes(value)) {
                    return (<div>
                      <div style={{ color: '#ccc' }}>{value}</div>
                    </div>)
                  } else {
                    return (<div>
                      <div className="format-option-label"><span>{label}</span></div>
                      <div style={{ color: '#ccc' }}>
                        {t('hs_code')}: {value}
                      </div>
                    </div>)
                  }
                }}
                noOptionsMessage={() => state.showHsCodeOptions ? t('no_option') : t('min_3_char')}
                onInputChange={handleHsCodeChange}
                onChange={(selected) => setLocalSearchParamsByKey('hsCode', selected.map(item => item.value).toString()) }
                onKeyDown={(event) => {
                  if (isHsCodeEmpty) {
                    handlePressEnterKey(event, 'countryToExport')
                  }
                  if (event.keyCode === 13) {
                    setIsHsCodeEmpty(true)
                  } else {
                    setIsHsCodeEmpty(false)
                  }
                }}
              />
            </div>
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <Label htmlFor="countryToExport" className="form-label">
              {t('importing_country')}
            </Label>
            <div className="form-control-wrap">
              <RSelect
                id="countryToExport"
                options={state.showCountryExportOptions ? selectBoxData.coverageData : []}
                defaultInputValue={(McPageHelpers.findCountryName(new URL(window.location.href).searchParams.get('countryToExport')) || state.allowedCountry?.label) ?? ''}
                isLoading={!selectBoxData.coverageData?.length}
                onInputChange={handleExportChange}
                noOptionsMessage={() => state.showCountryExportOptions ? t('no_option') : t('min_3_char')}
                onChange={(selected) => {
                  if (allowedCountries && !allowedCountries.includes(selected.countryCode) && !McPageHelpers.findCountryName(allowedCountries).includes(CONSTANTS.WORLD_COUNTRY_CODE)) {
                    fireErrorModal(
                      t('error_search_country_set_text', { exporter: McPageHelpers.findCountryName(selected.countryCode) }),
                      allowedCountries && allowedCountries.map((obj, index) => <span key={index}>{McPageHelpers.findCountryName(obj)}<br/></span>)
                    )
                    selected = null
                    setLocalSearchParamsByKey('countryToExport', selected)
                  } else {
                    setLocalSearchParamsByKey('countryToExport', selected.countryCode)
                    setLocalSearchParamsByKey('countryToExportName', selected.label)
                    setState(prevState => ({
                      ...prevState,
                      selectedCountry: selected,
                      allowedCountry: selected
                    }))
                    setLocalSearchParamsByKey('dataCoverage', selected.dataCoverage)
                  }
                }}
                onKeyDown={(event) => handlePressEnterKey(event, 'yourCountry')}
                filterOption={(option, query) => String(option.data.label + ' ' + (option.data.abbreviation ?? ''))
                  .toLocaleLowerCase(localeGSD)
                  .includes(query.toLocaleLowerCase(localeGSD))
                }
                value={state.allowedCountry}
              />
            </div>
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <Label htmlFor="yourCountry" className="form-label">
              {t('exporting_country')}
            </Label>
            <div className="form-control-wrap">
              <RSelect
                id="yourCountry"
                options={state.showYourCountryOptions ? selectBoxData.countries : []}
                defaultInputValue={McPageHelpers.findCountryName(new URL(window.location.href).searchParams.get('yourCountry')) ?? ''}
                isLoading={!selectBoxData.countries?.length}
                noOptionsMessage={() => state.showYourCountryOptions ? t('no_option') : t('min_3_char')}
                onInputChange={handleYourCountryChange}
                onChange={(selected) => setLocalSearchParamsByKey('yourCountry', selected.countryCode)}
                onKeyDown={(event) => handlePressEnterKey(event, 'productDesc', true)}
                filterOption={(option, query) => String(option.data.label + ' ' + (option.data.abbreviation ?? ''))
                  .toLocaleLowerCase(localeGSD)
                  .includes(query.toLocaleLowerCase(localeGSD))
                }
              />
            </div>
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <div className="form-group">
              <Label htmlFor="productDesc" className="form-label">
                {t('product_desc')}
              </Label>
              <div className="form-control-wrap">
                <input
                  id="productDesc"
                  className="form-control"
                  placeholder={t('product_desc')}
                  defaultValue={new URL(window.location.href).searchParams.get('productDesc') ?? ''}
                  onChange={(event) => setLocalSearchParamsByKey('productDesc', event.target.value)}
                  onKeyDown={(event) => handlePressEnterKey(event, 'searchBtn')}
                />
              </div>
            </div>
          </FormGroup>
        </Col>
        <Col lg="12">
          <div className="form-group">
            <Button
              id="searchBtn"
              className="btn-dim btn-block"
              color="primary"
              onClick={handleSearch}
              disabled={!isSearchBtnEnabled}
            >
              <Icon name="search"/>
              <span>{t('search')}</span>
            </Button>
          </div>
        </Col>
      </Row>

    </PreviewAltCard>
    <ErrorModal
      isOpen={modalFail}
      toggleModalFail={() => setModalFail((prevState) => !prevState)}
      title={errorModalTitle}
      message={errorModalMessage}
      linkText={errorModalHref ? t('error_anonymous_daily_search_linkText') : null}
      href={errorModalHref}
    />
  </div>)
}

export default SearchSidebar
