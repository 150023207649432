import React from 'react'

import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import Head from '../../../layout/head/Head'
import Footer from '../../../layout/footer/Footer'
import AppRoot from '../../../layout/global/AppRoot'
import AppWrap from '../../../layout/global/AppWrap'
import MailTemplateHeader from './MailTemplateHeader'

import ThemeProvider from '../theme/ThemeProvider'

import '../styles/globals.scss'
import 'flag-icons/css/flag-icons.min.css'

const MailTemplateLayout = ({ title, ...props }) => {
  return (
    <ThemeProvider systemMode='light'>
      <Head title={title}/>
      <AppRoot>
        <AppWrap>
          <MailTemplateHeader fixed/>
          <div
            className="nk-content"
            style={{
              marginTop: '67px',
              height: 'calc(100vh - 67px - 43px)', // vh-header-footer
              overflowY: 'auto'
            }}
          >
            <Outlet/>
          </div>
          <Footer/>
        </AppWrap>
      </AppRoot>
      <ToastContainer/>
    </ThemeProvider>

  )
}
export default MailTemplateLayout
