import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import {
  getCountryName,
  OVERVIEW_TAB_TYPE
} from '../../pages/mc-components/market-overview/MarketOverviewTable/MarketOverviewTable.settings'
import React from 'react'
import { useSDSsearchParams } from '../../store/searchDataStore'
import { useTranslation } from 'react-i18next'
import { useGlobalStoreData } from '../../store/globalStore'

const McSubTab = ({ activeTab, setActiveTab }) => {
  const searchParams = useSDSsearchParams()
  const { t } = useTranslation()
  const countriesGSD = useGlobalStoreData.countries()

  const toggleAltTab = (altTab) => {
    activeTab !== altTab && setActiveTab(altTab)
  }

  return (
    <Nav tabs className="nav-tabs-s2">
      <NavItem>
          <NavLink
            tag="a"
            href="#tab"
            className={classnames('btn btn-sm', { 'btn-primary': activeTab === OVERVIEW_TAB_TYPE.SOURCE })}
            onClick={(ev) => {
              ev.preventDefault()
              toggleAltTab(OVERVIEW_TAB_TYPE.SOURCE)
            }}
          >
            {getCountryName(searchParams.countryToExport, countriesGSD, 'country_code')}
          </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          tag="a"
          href="#tab"
          className={classnames('btn btn-sm', { 'btn-primary': activeTab === OVERVIEW_TAB_TYPE.DESTINATION })}
          onClick={(ev) => {
            ev.preventDefault()
            toggleAltTab(OVERVIEW_TAB_TYPE.DESTINATION)
          }}
        >
          {getCountryName(searchParams.yourCountry, countriesGSD, 'country_code')}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          tag="a"
          href="#tab"
          className={classnames('btn btn-sm', { 'btn-primary': activeTab === OVERVIEW_TAB_TYPE.WORLD })}
          onClick={(ev) => {
            ev.preventDefault()
            toggleAltTab(OVERVIEW_TAB_TYPE.WORLD)
          }}
        >
          {t('world')}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          tag="a"
          href="#tab"
          className={classnames('btn btn-sm', { 'btn-primary': activeTab === OVERVIEW_TAB_TYPE.CHANGE })}
          onClick={(ev) => {
            ev.preventDefault()
            toggleAltTab(OVERVIEW_TAB_TYPE.CHANGE)
          }}
        >
          {t('market_share_changes')}
        </NavLink>
      </NavItem>
    </Nav>
  )
}
export default McSubTab
