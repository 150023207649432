import McPageHelpers from './McPageHelpers'
import i18n from '../../utils/i18n'
import outliers from 'outliers'
import CONSTANTS, { ECOWAS_COUNTRY_CODES, EU_COUNTRY_CODES, SACU_COUNTRY_CODES } from '../../utils/Constants'
import { DutiesTransformer } from './DutiesTransformer'

const PARAM_NAMES = {
  COUNT: 'count',
  TOTAL_NUMBER_OF_COMPANY: 'total_company',
  HS_TOTAL_VALUE: 'hs_total_value',
  AVG_PRICE: 'avg_price',
  HS_COUNT: 'hs_count',
  MARKET_SHARE: 'market_share'
}

const generateQuantityUnit = (data) => {
  return {
    quantity1: data.quantity_unit1,
    quantity2: data.quantity_unit2,
    quantity3: data.quantity_unit3,
    quantity4: data.quantity_unit4
  }
}

const generateImporterTable = (data) => {
  return data?.map((item, key) => ({
    id: key,
    importerName: item.importer_name,
    web: item.web,
    hsTotalValue: item.hs_total_value,
    totalValue: item.total_value,
    marketShare: item.market_share,
    marketSharePercentage: item.market_share * 100,
    numberOfShipments: item.number_of_shipments,
    avgPrice: item.avg_price1,
    hsCrossQuantityUnit: McPageHelpers.sanitizeQuantity(item.quantity_unit1),
    hsTotalQuantity: item.hs_total_quantity1,
    growth: McPageHelpers.GROWTH_ICONS[item.growth],
    growthValue: item.growth_value,
    maxYearMonth: String(item.max_year_month).slice(4) + '.' + String(item.max_year_month).slice(0, 4),

    avgPrice1: item.avg_price1,
    avgPrice2: item.avg_price2,
    avgPrice3: item.avg_price3,
    avgPrice4: item.avg_price4,

    quantity1: McPageHelpers.sanitizeQuantity(item.quantity_unit1),
    quantity2: McPageHelpers.sanitizeQuantity(item.quantity_unit2),
    quantity3: McPageHelpers.sanitizeQuantity(item.quantity_unit3),
    quantity4: McPageHelpers.sanitizeQuantity(item.quantity_unit4),

    hsTotalQuantity1: item.hs_total_quantity1,
    hsTotalQuantity2: item.hs_total_quantity2,
    hsTotalQuantity3: item.hs_total_quantity3,
    hsTotalQuantity4: item.hs_total_quantity4,

    cityState: item.city_state,
    email: item.E_MAIL,
    fax: item.FAX,
    importerAddress: item.importer_address,
    tel: item.TEL,
    distinctCountry: Number(item.distinct_country),
    facebook: item.facebook,
    hsCode: item.hs_code,
    hsCount: item.hs_count,
    instagram: item.instagram,
    linkedin: item.linkedin,
    twitter: item.twitter,

    yearChartData: McPageHelpers.generateYearChartData(item.year_percentage, i18n.t('sp_years_ago')),
    yearPercentage: item.year_percentage,

    productList: item.product_list,
    countryList: item.country_list,
    sector_list: item.sector_list,

    importerFilters: {
      importer: McPageHelpers.generateCountFilterValue(item.year_label),
      supplier: McPageHelpers.generateCountFilterValue(Number(item.distinct_trader)),
      country: McPageHelpers.generateCountFilterValue(Number(item.distinct_country)),
      hsCode: McPageHelpers.generateCountFilterValue(item.hs_count),
      isLogistic: McPageHelpers.getCompanyLogisticFirmStatus(item),
      growth: item.growth
    }
  }))
}

const generateProductAnalysisBarPieData = (rawData) => {
  const companyValueBar = rawData?.company_value !== null
    ? rawData?.company_value?.map(item => ({
      ...item,
      yValue: item.yValue,
      tooltipYValue: McPageHelpers.numToLocaleString(item.yValue, 'percent'),
      formattedYValue: McPageHelpers.numToLocaleString((item.yValue * 100), 'decimal', false, 1),
      yValueForAxis: item.yValue * 100
    }))
    : null
  const onlyHsCountPie = rawData?.only_hs_count !== null
    ? rawData?.only_hs_count.map(item => ({
      ...item,
      tooltipValue: McPageHelpers.numToLocaleString(item.value, 'percent')
    }))
    : null
  const companyCountBar = rawData?.company_count !== null
    ? rawData?.company_count.map(item => ({
      ...item,
      yValue: item.yValue,
      tooltipYValue: McPageHelpers.numToLocaleString(item.yValue, 'percent'),
      formattedYValue: McPageHelpers.numToLocaleString((item.yValue * 100), 'decimal', false, 1),
      yValueForAxis: item.yValue * 100
    }))
    : null
  const countryCountPie = rawData?.country_count !== null
    ? rawData?.country_count.map(item => ({
      ...item,
      tooltipValue: McPageHelpers.numToLocaleString(item.value, 'percent')
    }))
    : null
  const companyShipmentCountBar = rawData?.company_shipment_count !== null
    ? rawData?.company_shipment_count.map(item => ({
      ...item,
      description: McPageHelpers.numToLocaleString(item.yValue),
      tooltipYValue: McPageHelpers.numToLocaleString(item.yValue),
      formattedYValue: McPageHelpers.numToLocaleString(item.yValue)
    }))
    : null

  return {
    cvBar: companyValueBar,
    cvBarExp: rawData?.companyValueExplanation,
    ohcPie: onlyHsCountPie,
    ohcPieExp: rawData?.hsCountExplanation,
    ccBar: companyCountBar,
    ccBarExp: rawData?.companyCountExplanation,
    ccPie: countryCountPie,
    ccPieExp: rawData?.countryCountExplanation,
    cscBar: companyShipmentCountBar,
    cscBarExp: rawData?.shipmentCountExplanation,
    growth: rawData?.growth,
    growthExp: rawData?.growthExplanation
  }
}

const generateImporterTableSectionData = (data) => {
  if (data) {
    return {
      table: generateImporterTable(data),
      units: generateQuantityUnit(data[0])
    }
  }
  return { table: [], units: {} }
}

const getCountByParam = (data, param) => {
  return data?.reduce((total, obj) => total + obj[param], 0)
}

const generateImporterMapBarSectionData = (data, countries, selectedUnit) => {
  return {
    map: generateHeatMapData(data, PARAM_NAMES.COUNT, countries, selectedUnit),
    bar: generateHeatBarData(data, PARAM_NAMES.COUNT, countries, null, null, selectedUnit),
    count: getCountByParam(data, PARAM_NAMES.COUNT)
  }
}

const generatePotentialMapBarSectionData = (data, countries, selectedUnit) => {
  return {
    map: generateHeatMapData(data, PARAM_NAMES.TOTAL_NUMBER_OF_COMPANY, countries, selectedUnit),
    bar: generateHeatBarData(data, PARAM_NAMES.TOTAL_NUMBER_OF_COMPANY, countries, null, null, selectedUnit), // PARAM_NAMES.TOTAL_NUMBER_OF_COMPANY
    count: getCountByParam(data, PARAM_NAMES.TOTAL_NUMBER_OF_COMPANY)
  }
}

const generatePriceAnalysisMapBarSectionData = (data, countries, selectedUnit, companyCountData) => {
  return {
    map: generateHeatMapData(data, PARAM_NAMES.AVG_PRICE, countries, selectedUnit),
    bar: generateHeatBarData(data, PARAM_NAMES.AVG_PRICE, countries, PARAM_NAMES.COUNT, companyCountData)
  }
}

const generatePriceAnalysisWorldMapBarSectionData = (data, countries, selectedUnit, selectedUnitNumber, companyCountData) => {
  return {
    map: generateHeatMapData(data, PARAM_NAMES.AVG_PRICE + selectedUnitNumber, countries, selectedUnit),
    bar: generateHeatBarData(data, PARAM_NAMES.AVG_PRICE + selectedUnitNumber, countries, PARAM_NAMES.HS_TOTAL_VALUE, companyCountData)
  }
}

const generatePotentialTable = (data) => {
  const mainData = data?.map((item, key) => ({
    id: key,
    hsCode: item.hs_code,
    totalValue: item.total_value,
    numberOfShipments: item.number_of_shipments,
    distinctCountry: item.distinct_country,
    hsCount: item.hs_count,
    growth: McPageHelpers.GROWTH_ICONS[item.growth],
    importerName: item.importer_name,
    importerAddress: item.importer_address,
    cityState: item.city_state,

    tel: item.tel,
    fax: item.fax,
    email: item.e_mail,
    web: item.web,

    facebook: item.facebook,
    instagram: item.instagram,
    linkedin: item.linkedin,
    twitter: item.twitter,

    growthValue: item.growth_value,

    yearChartData: McPageHelpers.generateYearChartData(item.year_percentage, i18n.t('sp_years_ago')),
    yearPercentage: item.year_percentage,

    productList: item.product_list,
    countryList: item.country_list,
    sector_list: item.sector_list,

    potentialFilters: {
      importer: McPageHelpers.generateCountFilterValue(item.year_label),
      supplier: McPageHelpers.generateCountFilterValue(Number(item.distinct_trader)),
      country: McPageHelpers.generateCountFilterValue(Number(item.distinct_country)),
      hsCode: McPageHelpers.generateCountFilterValue(item.hs_count),
      isLogistic: McPageHelpers.getCompanyLogisticFirmStatus(item)
    }
  }))

  if (mainData) {
    for (const item of mainData) {
      item.countryList = item.countryList.filter(country => country.name !== null || country.country_code !== 'Null')
    }
  }

  return mainData
}

const generateHeatMapData = (arr, paramName, countries, selectedUnit) => {
  const countryCodes = arr?.map(item => item.country_code)
  const countryArray = countries?.filter(country => !countryCodes?.includes(country.countryCode))
  const allCountries = arr?.concat(countryArray)
  const generatedValue = allCountries?.map(item => {
    const id = item.country_code ?? item.countryCode
    const countryName = McPageHelpers.findCountryName(id)
    return ({
      id,
      countryName,
      value: item[paramName],
      formatValue: McPageHelpers.numToLocaleString(item[paramName], 'decimal', false, 2, 2),
      tooltipTxt: generateTooltip(item, countryName, selectedUnit, paramName),
      avgPrice: McPageHelpers.numToLocaleString(item[selectedUnit], 'decimal', false, 2, 2)
    })
  })
  generatedValue?.forEach(item => {
    if (item.id === 'UK') {
      item.id = 'GB'
    } else if (item.id === 'KV') {
      item.id = 'XK'
    }
  })
  return generatedValue
}

export const generateHeatBarData = (arr, paramName, countries, sortParamName, companyCount = false, selectedUnit) => {
  const generateFormatValue = (item) => {
    if (paramName === PARAM_NAMES.COUNT || paramName === PARAM_NAMES.TOTAL_NUMBER_OF_COMPANY) {
      return McPageHelpers.numToLocaleString(item[paramName])
    }
    return McPageHelpers.numToLocaleString(item[paramName], 'decimal', false, 2, 2)
  }

  const processedArray = arr?.map(item => ({
    ...item,
    xValue: item.country_code,
    yValue: item[paramName],
    description: '\n' + McPageHelpers.findCountryName(item.country_code ?? item.countryCode), // countries.filter(country => country.value === item.country_code)[0]?.label,
    formatValue: generateFormatValue(item),
    tooltipYValue: McPageHelpers.numToLocaleString(item[paramName])
  }))

  if (!companyCount) {
    return processedArray?.sort((a, b) => {
      if (a.yValue === null) return 1
      if (b.yValue === null) return -1
      return b[sortParamName] - a[sortParamName]
    })
  }

  const processedArrayMap = new Map(companyCount?.map(item => [item.country_code, item]))

  const mergedData = processedArray?.map(item => {
    const processedArrayItem = processedArrayMap?.get(item.country_code)
    return { ...item, ...processedArrayItem }
  })

  const sortedData = mergedData?.sort((a, b) => {
    if (a.yValue === null) return 1
    if (b.yValue === null) return -1
    return b[sortParamName ?? 'count'] - a[sortParamName ?? 'count']
  })

  return sortedData
}

const generateTooltip = (item, countryName, selectedUnit, paramName) => {
  const { HEAT_MAP_PARAMS, numToLocaleString } = McPageHelpers
  const formatPrice = value => {
    const priceValue = value || value === 0 ? numToLocaleString(value, 'decimal', false, 2, 2) : '-'
    return i18n.t('avg_price_bar_title') + ': ' + priceValue
  }
  const formatCompanyCount = value => {
    return i18n.t('number_of_importer_companies') + ': ' + (value || value === 0 ? numToLocaleString(value) : '-')
  }
  const formatShipment = value => {
    return `${i18n.t('sp_company_shipment_y')}: ${value || value === 0 ? numToLocaleString(value) : '-'}`
  }
  const prependCountry = text => {
    return countryName ? `${countryName}:\n${text}` : text
  }

  const priceValue = item[selectedUnit] !== undefined ? item[selectedUnit] : item.avgPrice

  if (item[HEAT_MAP_PARAMS.TOTAL_NUMBER_OF_COMPANY] && priceValue !== undefined) {
    return prependCountry(
      `${formatPrice(priceValue)}\n${formatCompanyCount(item[HEAT_MAP_PARAMS.TOTAL_NUMBER_OF_COMPANY])}`
    )
  }

  if (item.count) {
    return prependCountry(formatShipment(item.count))
  }

  if (item[paramName]) {
    let tooltipTxt = formatPrice(item[paramName])
    if (item[HEAT_MAP_PARAMS.TOTAL_NUMBER_OF_COMPANY]) {
      tooltipTxt += `\n${formatCompanyCount(item[HEAT_MAP_PARAMS.TOTAL_NUMBER_OF_COMPANY])}`
    }
    return prependCountry(tooltipTxt)
  }

  return prependCountry(i18n.t('no_data'))
}

const generateAverageMonthlyImportUnitPricesByCountriesData = (monthly, countries, selectedUnitNumber) => {
  const expandedResult = monthly?.data && Object.values(monthly?.data)?.reduce((x, y) => {
    (x[y.yearMonth] = x[y.yearMonth] || []).push(y)
    return x
  }, {})

  const data = expandedResult && Object.values(expandedResult)?.map((a) => {
    const year = a.reduce((acc, obj) => {
      return obj.yearMonth
    }, 0)

    const sumHsTotalQuantity1 = a.reduce((acc, obj) => {
      if (obj.hsTotalQuantity1 !== 0 && obj.hsQuantityTotalValue1 !== 0 && obj.hsTotalQuantity1 !== null && obj.hsQuantityTotalValue1 !== null) {
        return acc + obj.hsTotalQuantity1
      }
      return acc
    }, 0)
    const sumHsTotalQuantity2 = a.reduce((acc, obj) => {
      if (obj.hsTotalQuantity2 !== 0 && obj.hsQuantityTotalValue2 !== 0 && obj.hsTotalQuantity2 !== null && obj.hsQuantityTotalValue2 !== null) {
        return acc + obj.hsTotalQuantity2
      }
      return acc
    }, 0)
    const sumHsTotalQuantity3 = a.reduce((acc, obj) => {
      if (obj.hsTotalQuantity3 !== 0 && obj.hsQuantityTotalValue3 !== 0 && obj.hsTotalQuantity3 !== null && obj.hsQuantityTotalValue3 !== null) {
        return acc + obj.hsTotalQuantity3
      }
      return acc
    }, 0)
    const sumHsTotalQuantity4 = a.reduce((acc, obj) => {
      if (obj.hsTotalQuantity4 !== 0 && obj.hsQuantityTotalValue4 !== 0 && obj.hsTotalQuantity4 !== null && obj.hsQuantityTotalValue4 !== null) {
        return acc + obj.hsTotalQuantity4
      }
      return acc
    }, 0)
    const sumHsQuantityTotalValue1 = a.reduce((acc, obj) => {
      if (obj.hsTotalQuantity1 !== 0 && obj.hsQuantityTotalValue1 !== 0 && obj.hsTotalQuantity1 !== null && obj.hsQuantityTotalValue1 !== null) {
        return acc + obj.hsQuantityTotalValue1
      }
      return acc
    }, 0)
    const sumHsQuantityTotalValue2 = a.reduce((acc, obj) => {
      if (obj.hsTotalQuantity2 !== 0 && obj.hsQuantityTotalValue2 !== 0 && obj.hsTotalQuantity2 !== null && obj.hsQuantityTotalValue2 !== null) {
        return acc + obj.hsQuantityTotalValue2
      }
      return acc
    }, 0)
    const sumHsQuantityTotalValue3 = a.reduce((acc, obj) => {
      if (obj.hsTotalQuantity3 !== 0 && obj.hsQuantityTotalValue3 !== 0 && obj.hsTotalQuantity3 !== null && obj.hsQuantityTotalValue3 !== null) {
        return acc + obj.hsQuantityTotalValue3
      }
      return acc
    }, 0)
    const sumHsQuantityTotalValue4 = a.reduce((acc, obj) => {
      if (obj.hsTotalQuantity4 !== 0 && obj.hsQuantityTotalValue4 !== 0 && obj.hsTotalQuantity4 !== null && obj.hsQuantityTotalValue4 !== null) {
        return acc + obj.hsQuantityTotalValue4
      }
      return acc
    }, 0)

    const result = {
      yearMonth: year,
      traderCountryCode: 'World',
      hsQuantityTotalValue1: sumHsQuantityTotalValue1,
      hsQuantityTotalValue2: sumHsQuantityTotalValue2,
      hsQuantityTotalValue3: sumHsQuantityTotalValue3,
      hsQuantityTotalValue4: sumHsQuantityTotalValue4,
      hsTotalQuantity1: sumHsTotalQuantity1,
      hsTotalQuantity2: sumHsTotalQuantity2,
      hsTotalQuantity3: sumHsTotalQuantity3,
      hsTotalQuantity4: sumHsTotalQuantity4
    }
    monthly?.data?.push(result)
    return result
  })

  return {
    line: generateLineChartData(data, selectedUnitNumber),
    table: generateMonthlyTableData(monthly)
  }
}

const generateMonthlyTableData = (monthly) => {
  const result = {}
  if (monthly?.data) {
    Object.values(monthly?.data)?.forEach(item => {
      const {
        traderCountryCode,
        yearMonth,
        hsTotalQuantity1,
        hsTotalQuantity2,
        hsTotalQuantity3,
        hsTotalQuantity4,
        hsQuantityTotalValue1,
        hsQuantityTotalValue2,
        hsQuantityTotalValue3,
        hsQuantityTotalValue4
      } = item
      if (!result[traderCountryCode]) {
        result[traderCountryCode] = {
          yearMonth: Array(12).fill(0),
          hsTotalQuantity1: Array(12).fill(0),
          hsTotalQuantity2: Array(12).fill(0),
          hsTotalQuantity3: Array(12).fill(0),
          hsTotalQuantity4: Array(12).fill(0),
          hsQuantityTotalValue1: Array(12).fill(0),
          hsQuantityTotalValue2: Array(12).fill(0),
          hsQuantityTotalValue3: Array(12).fill(0),
          hsQuantityTotalValue4: Array(12).fill(0)

        }
      }

      const index = yearMonth % 100 - 1
      result[traderCountryCode].yearMonth[index] = yearMonth || 0
      result[traderCountryCode].hsTotalQuantity1[index] += hsTotalQuantity1 || 0
      result[traderCountryCode].hsTotalQuantity2[index] += hsTotalQuantity2 || 0
      result[traderCountryCode].hsTotalQuantity3[index] += hsTotalQuantity3 || 0
      result[traderCountryCode].hsTotalQuantity4[index] += hsTotalQuantity4 || 0
      result[traderCountryCode].hsQuantityTotalValue1[index] += hsQuantityTotalValue1 || 0
      result[traderCountryCode].hsQuantityTotalValue2[index] += hsQuantityTotalValue2 || 0
      result[traderCountryCode].hsQuantityTotalValue3[index] += hsQuantityTotalValue3 || 0
      result[traderCountryCode].hsQuantityTotalValue4[index] += hsQuantityTotalValue4 || 0
    })

    const output = []
    for (const countryCode in result) {
      const {
        yearMonth,
        hsTotalQuantity1, hsTotalQuantity2, hsTotalQuantity3, hsTotalQuantity4,
        hsQuantityTotalValue1,
        hsQuantityTotalValue2,
        hsQuantityTotalValue3,
        hsQuantityTotalValue4
      } = result[countryCode]
      output.push({
        countryCode,
        yearMonth,
        hsTotalQuantity1,
        hsTotalQuantity2,
        hsTotalQuantity3,
        hsTotalQuantity4,

        hsQuantityTotalValue1,
        hsQuantityTotalValue2,
        hsQuantityTotalValue3,
        hsQuantityTotalValue4
      })
    }

    const sortedData = output.map(item => {
      const sortedItem = {}
      sortedItem.countryCode = item.countryCode
      sortedItem.yearMonth = monthly?.timeTags.filter(tag => item.yearMonth.includes(tag))

      for (const key in item) {
        if (key !== 'countryCode' && key !== 'yearMonth') {
          sortedItem[key] = monthly?.timeTags?.map(tag => {
            const index = item.yearMonth.indexOf(tag)
            return index !== -1 ? item[key][index] : 0
          })
        }
      }

      return sortedItem
    })

    return sortedData?.reverse()
  }
}

const fillMissingMonths = (data) => {
  if (!data) return
  const result = []
  const startYearMonth = data[0]?.yearMonth
  const endYearMonth = data[data?.length - 1]?.yearMonth

  for (let yearMonth = startYearMonth; yearMonth <= endYearMonth; yearMonth++) {
    const found = data.find(entry => entry.yearMonth === yearMonth)
    if (found) {
      result.push(found)
    } else {
      const missingEntry = {
        yearMonth,
        traderCountryCode: 'World',
        hsQuantityTotalValue1: 0,
        hsQuantityTotalValue2: 0,
        hsQuantityTotalValue3: 0,
        hsQuantityTotalValue4: 0,
        hsTotalQuantity1: 0,
        hsTotalQuantity2: 0,
        hsTotalQuantity3: 0,
        hsTotalQuantity4: 0
      }
      result.push(missingEntry)
    }

    if ((yearMonth % 100) === 12) {
      yearMonth = (Math.floor(yearMonth / 100) + 1) * 100
    }
  }

  return result
}

const changeZeroOrNull = (value) => {
  if (value === 0 || value === 'NaN') return '-'
  return McPageHelpers.numToLocaleString(value, 'decimal', false, 2, 2)
}

const generateLineChartData = (datapoint, selectedUnitNumber) => {
  const filledData = fillMissingMonths(datapoint)
  if (filledData) {
    const generatedValue = Object.values(filledData)?.map((item) => {
      const date = item?.yearMonth.toString() ?? '-'
      const lastTwoCharacter = date.slice(-2)
      const firstFourCharacter = date.slice(0, 4)
      return ({
        date: new Date(Number(firstFourCharacter), Number(lastTwoCharacter), 0).getTime(),
        value: calculateAvg(item, selectedUnitNumber),
        tooltip: i18n.t('sp_filter_by_avg_price') + ': ' + changeZeroOrNull(calculateAvg(item, selectedUnitNumber))
      })
    })
    return generatedValue.reverse()
  }
}

const calculateAvg = (item, selectedUnitNumber) => {
  switch (selectedUnitNumber) {
    case McPageHelpers.UNIT_NUMBERS.N1:
      return item.hsQuantityTotalValue1 ? (item.hsQuantityTotalValue1 / item.hsTotalQuantity1) : 0
    case McPageHelpers.UNIT_NUMBERS.N2:
      return item.hsQuantityTotalValue2 ? item.hsQuantityTotalValue2 / item.hsTotalQuantity2 : 0
    case McPageHelpers.UNIT_NUMBERS.N3:
      return item.hsQuantityTotalValue3 ? item.hsQuantityTotalValue3 / item.hsTotalQuantity3 : 0
    case McPageHelpers.UNIT_NUMBERS.N4:
      return item.hsQuantityTotalValue4 ? item.hsQuantityTotalValue4 / item.hsTotalQuantity4 : 0
    default:
      break
  }
}

const generateHistogramData = (arr, paramName, rawTableData, yValueParamName, chartType = McPageHelpers.MOUNTAIN_CHART_TYPE.MOUNTAIN) => {
  const numberOfBins = CONSTANTS.NUMBER_OF_BINS

  if (arr?.length > 0) {
    const max = Math.max(...arr)
    const min = Math.min(...arr)
    const len = max - min // + 1;
    const binRange = len / numberOfBins
    let bins = new Array(numberOfBins).fill(0)
    arr.forEach((x) => {
      let ind = Math.round((x - min) / binRange)
      if (ind === numberOfBins) ind--
      bins[ind]++
    })

    bins = bins.map((item, key) => {
      const start = min + binRange * key
      const end = start + binRange

      let xValue = min + (binRange * key) + (binRange / 2)

      if (key === 0) xValue = min
      if (key === numberOfBins - 1) xValue = max

      const result = {
        key,
        // text: start + '-' + end,
        xValue,
        yValue: item
      }

      if (chartType === McPageHelpers.MOUNTAIN_CHART_TYPE.MOUNTAIN && yValueParamName) {
        const newYValue = rawTableData
          .filter(item => item[paramName] >= start && item[paramName] <= end)
          .reduce((n, item) => n + item[yValueParamName], 0)
        result.yValue = newYValue === PARAM_NAMES.MARKET_SHARE ? newYValue * 100 : newYValue
      }

      return result
    })

    return bins
  } else return []
}

const removeOutliers = (array, outlierIndex, loopNum) => {
  const arr = array?.filter(val => val[outlierIndex] !== null)
  let resultArray = arr?.filter(outliers(outlierIndex))

  for (let i = 0; i < loopNum - 1; i++) {
    resultArray = resultArray?.filter(outliers(outlierIndex))
  }

  return resultArray
}

const generateRawMountainData = (rawData, paramName, yValueParamName) => {
  const data = rawData?.map((item) => item[paramName])
  const chartData = generateHistogramData(data, paramName, rawData, yValueParamName)

  return {
    data: chartData,
    min: data ? Math.min(...data) : '',
    max: data ? Math.max(...data) : ''
  }
}

const generateRemovedOutliersMountainData = (rawData, paramName, yValueParamName) => {
  const removedOutliers = removeOutliers(rawData, paramName, 1)
  const data = removedOutliers?.map((item) => item[paramName])
  const chartData = generateHistogramData(data, paramName, rawData, yValueParamName)

  return {
    data: chartData,
    min: data ? Math.min(...data) : '',
    max: data ? Math.max(...data) : ''
  }
}

const generateSingleMountainChartData = (data, paramName, yValueParamName) => {
  const raw = generateRawMountainData(data, paramName, yValueParamName)
  const out = generateRemovedOutliersMountainData(data, paramName, yValueParamName)

  return { raw, out }
}

const generateMountainChartsData = (data, selectedUnitNumber) => {
  const hsTotalValueData = generateSingleMountainChartData(data, PARAM_NAMES.HS_TOTAL_VALUE)
  const avgPriceXFirmNumData = generateSingleMountainChartData(data, PARAM_NAMES.AVG_PRICE + selectedUnitNumber)

  const hsCountData = generateSingleMountainChartData(data, PARAM_NAMES.HS_COUNT, PARAM_NAMES.HS_TOTAL_VALUE)
  const avgPriceXHsImportCurrencyData = generateSingleMountainChartData(data, PARAM_NAMES.AVG_PRICE + selectedUnitNumber, PARAM_NAMES.HS_TOTAL_VALUE)
  const avgPriceXHsImportInterestData = generateSingleMountainChartData(data, PARAM_NAMES.AVG_PRICE + selectedUnitNumber, PARAM_NAMES.MARKET_SHARE)

  return {
    hsTotalMountain: hsTotalValueData,
    hsCountMountain: hsCountData,
    avgPriceMountain: avgPriceXFirmNumData,
    avgCurrencyMountain: avgPriceXHsImportCurrencyData,
    avgInterestMountain: avgPriceXHsImportInterestData
  }
}

const formatBarAndLineData = (value) => {
  return Number(Math.round(McPageHelpers.numDivisionByThousand(value)).toFixed(0))
}

const generateLineChartDataForMarketOverview = (obj, paramName, countries, isAverage) => {
  if (obj?.timeTags) {
    const timesArr = obj?.timeTags
    const keysToCheck = ['value', 'value2', 'value3', 'value4', 'value5']
    const countriesARR = obj[paramName]?.sort((a, b) => b[['valuesByTimeTag'][0]] - a[['valuesByTimeTag'][0]]).slice(1, 6)
    let generatedValue
    if (isAverage) {
      generatedValue = timesArr?.map(item => {
        const data = ({
          date: generateFullTime(item),
          value: countriesARR[0]?.valuesByTimeTag[timesArr.indexOf(item)],
          value2: countriesARR[1]?.valuesByTimeTag[timesArr.indexOf(item)],
          value3: countriesARR[2]?.valuesByTimeTag[timesArr.indexOf(item)],
          value4: countriesARR[3]?.valuesByTimeTag[timesArr.indexOf(item)],
          value5: countriesARR[4]?.valuesByTimeTag[timesArr.indexOf(item)],
          countriesArray: countriesARR.map(item => getCountryName(item.countryCode, countries))
        })
        if (keysToCheck.some(key => data[key] === 'Infinity')) {
          return false
        } else {
          return data
        }
      })
    } else {
      generatedValue = timesArr?.map(item => {
        const data = ({
          date: generateFullTime(item),
          value: formatBarAndLineData(countriesARR[0]?.valuesByTimeTag[timesArr.indexOf(item)]),
          value2: formatBarAndLineData(countriesARR[1]?.valuesByTimeTag[timesArr.indexOf(item)]),
          value3: formatBarAndLineData(countriesARR[2]?.valuesByTimeTag[timesArr.indexOf(item)]),
          value4: formatBarAndLineData(countriesARR[3]?.valuesByTimeTag[timesArr.indexOf(item)]),
          value5: formatBarAndLineData(countriesARR[4]?.valuesByTimeTag[timesArr.indexOf(item)]),
          countriesArray: countriesARR.map(item => getCountryName(item.countryCode, countries))
        })
        if (keysToCheck.some(key => data[key] === 'Infinity')) {
          return false
        } else {
          return data
        }
      })
    }

    return generatedValue
  }

  return obj
}

const generateHeatBarDataForMarketOverview = (obj, paramName, countries, isAverage) => {
  if (obj?.timeTags) {
    const timeTags = obj.timeTags?.slice(2).reverse()
    let generatedValue
    if (isAverage) {
      generatedValue = obj[paramName].sort((a, b) => b[['valuesByTimeTag'][0]] - a[['valuesByTimeTag'][0]]).slice(1, 11).map(item => ({
        country: getCountryName(item.countryCode, countries),
        [timeTags[0]]: item.valuesByTimeTag[4],
        [timeTags[1]]: item.valuesByTimeTag[3],
        [timeTags[2]]: item.valuesByTimeTag[2]
      }))
    } else {
      generatedValue = obj[paramName].sort((a, b) => b[['valuesByTimeTag'][0]] - a[['valuesByTimeTag'][0]]).slice(1, 11).map(item => ({
        country: getCountryName(item.countryCode, countries),
        [timeTags[0]]: formatBarAndLineData(item.valuesByTimeTag[4]),
        [timeTags[1]]: formatBarAndLineData(item.valuesByTimeTag[3]),
        [timeTags[2]]: formatBarAndLineData(item.valuesByTimeTag[2])
      }))
    }
    return generatedValue
  }
}

const getCountryName = (item, countries) => {
  return countries?.find(country => country.countryId === item)?.countryName ?? '-'
}

const generateFullTime = (dateOnlyYearAndMonth) => {
  if (dateOnlyYearAndMonth.toString()?.length === 6) { return new Date(Number(String(dateOnlyYearAndMonth).slice(0, 4)), Number(String(dateOnlyYearAndMonth).slice(4)), 0).getTime() }
}

const generateMarketOverviewDataByParamName = (data, countries, isAverage) => {
  return {
    destinationCountryAsExporterYearlyExplanation: data?.destinationCountryAsExporterYearlyExplanation,
    destinationCountryAsExporterMonthlyExplanation: data?.destinationCountryAsExporterMonthlyExplanation,
    importerYearlyExplanation: data?.importerYearlyExplanation,
    importerMonthlyExplanation: data?.importerMonthlyExplanation,
    exporterYearlyExplanation: data?.exporterYearlyExplanation,
    exporterMonthlyExplanation: data?.exporterMonthlyExplanation,
    worldImportYearlyExplanation: data?.worldImportYearlyExplanation,
    worldImportMonthlyExplanation: data?.worldImportMonthlyExplanation,
    worldExportYearlyExplanation: data?.worldExportYearlyExplanation,
    worldExportMonthlyExplanation: data?.worldExportMonthlyExplanation,
    sourceCountryAsImporterYearlyExplanation: data?.sourceCountryAsImporterYearlyExplanation,
    sourceCountryAsImporterMonthlyExplanation: data?.sourceCountryAsImporterMonthlyExplanation,
    importer: {
      country: {
        monthly: data?.importerMonthly?.countryValues,
        yearly: data?.importerYearly?.countryValues
      },
      time: {
        monthly: data?.importerMonthly?.timeTags,
        yearly: data?.importerYearly?.timeTags
      },
      data: {
        monthly: generateLineChartDataForMarketOverview(data?.importerMonthly, 'countryValues', countries, isAverage),
        yearly: generateHeatBarDataForMarketOverview(data?.importerYearly, 'countryValues', countries, isAverage)
      }

    },
    exporter: {
      country: {
        monthly: data?.exporterMonthly?.countryValues,
        yearly: data?.exporterYearly?.countryValues
      },
      time: {
        monthly: data?.exporterMonthly?.timeTags,
        yearly: data?.exporterYearly?.timeTags
      },
      data: {
        monthly: generateLineChartDataForMarketOverview(data?.exporterMonthly, 'countryValues', countries, isAverage),
        yearly: generateHeatBarDataForMarketOverview(data?.exporterYearly, 'countryValues', countries, isAverage)
      }
    },
    world: {
      importer: {
        country: {
          monthly: data?.worldImportMonthly?.countryValues,
          yearly: data?.worldImportYearly?.countryValues
        },
        time: {
          monthly: data?.worldImportMonthly?.timeTags,
          yearly: data?.worldImportYearly?.timeTags
        },
        data: {
          monthly: generateLineChartDataForMarketOverview(data?.worldImportMonthly, 'countryValues', countries, isAverage),
          yearly: generateHeatBarDataForMarketOverview(data?.worldImportYearly, 'countryValues', countries, isAverage)
        }
      },
      exporter: {
        country: {
          monthly: data?.worldExportMonthly?.countryValues,
          yearly: data?.worldExportYearly?.countryValues
        },
        time: {
          monthly: data?.worldExportMonthly?.timeTags,
          yearly: data?.worldExportYearly?.timeTags
        },
        data: {
          monthly: generateLineChartDataForMarketOverview(data?.worldExportMonthly, 'countryValues', countries, isAverage),
          yearly: generateHeatBarDataForMarketOverview(data?.worldExportYearly, 'countryValues', countries, isAverage)
        }
      }
    },
    sourceCountry: {
      country: {
        monthly: data?.sourceCountryAsImporterMonthly?.countryValues,
        yearly: data?.sourceCountryAsImporterYearly?.countryValues
      },
      time: {
        monthly: data?.sourceCountryAsImporterMonthly?.timeTags,
        yearly: data?.sourceCountryAsImporterYearly?.timeTags
      },
      data: {
        monthly: generateLineChartDataForMarketOverview(data?.sourceCountryAsImporterMonthly, 'countryValues', countries, isAverage),
        yearly: generateHeatBarDataForMarketOverview(data?.sourceCountryAsImporterYearly, 'countryValues', countries, isAverage)
      }
    },
    destinationCountry: {
      country: {
        monthly: data?.destinationCountryAsExporterMonthly?.countryValues,
        yearly: data?.destinationCountryAsExporterYearly?.countryValues
      },
      time: {
        monthly: data?.destinationCountryAsExporterMonthly?.timeTags,
        yearly: data?.destinationCountryAsExporterYearly?.timeTags
      },
      data: {
        monthly: generateLineChartDataForMarketOverview(data?.destinationCountryAsExporterMonthly, 'countryValues', countries, isAverage),
        yearly: generateHeatBarDataForMarketOverview(data?.destinationCountryAsExporterYearly, 'countryValues', countries, isAverage)
      }
    }

  }
}

const generateAndSetImporterSectionData = (rawData, countries, selectedUnit, set) => {
  const importerTableSectionData = generateImporterTableSectionData(rawData?.spTable?.data?.tableData)
  const importerTableSectionExplanation = rawData?.spTable?.data?.tableExplanation
  const importerGraphMapSectionData = generateImporterMapBarSectionData(rawData?.spGraph?.data?.company_count_per_country, countries, selectedUnit)
  const importerGraphMapSectionExplanation = rawData?.spGraph?.data?.companyCountPerCountryExplanation
  set(importerTableSectionData.table, importerTableSectionExplanation, importerGraphMapSectionExplanation, importerTableSectionData.units,
    importerGraphMapSectionData.map, importerGraphMapSectionData.bar, importerGraphMapSectionData.count)
}

const generateAndSetPotentialSectionData = (rawData, countries, selectedUnit, set) => {
  const potentialTableSectionData = generatePotentialTable(rawData?.rfTable?.data?.tableData)
  const potentialTableSectionDataExplanation = rawData?.rfTable?.data?.tableExplanation

  const potentialGraphMapSectionData = generatePotentialMapBarSectionData(rawData?.spSummary?.data?.summaryData, countries, selectedUnit)
  const potentialGraphMapSectionDataExplanation = rawData?.spSummary?.data?.summaryExplanation
  set(potentialTableSectionData, potentialTableSectionDataExplanation, potentialGraphMapSectionDataExplanation, potentialGraphMapSectionData.map, potentialGraphMapSectionData.bar, potentialGraphMapSectionData.count)
}
const generateAndSetProductAnalysisSectionData = (rawData, set) => {
  const data = generateProductAnalysisBarPieData(rawData?.spGraph?.data)
  set(data.cvBar, data.cvBarExp, data.ohcPie, data.ohcPieExp, data.ccBar, data.ccBarExp, data.ccPie, data.ccPieExp, data.cscBar, data.cscBarExp, data.growth, data.growthExp)
}

const generateAndSetPriceAnalysisSectionData = (rawData, countries, selectedUnitNumber = McPageHelpers.UNIT_NUMBERS.N1, selectedUnit, searchCountryCode, set) => {
  const data = rawData?.spGraph?.data ? rawData?.spGraph?.data['average_price' + selectedUnitNumber]?.filter(item => item.id !== searchCountryCode) : null
  const averageMonthlySectionData = generateAverageMonthlyImportUnitPricesByCountriesData(rawData?.monthly, countries, selectedUnitNumber)
  const mountainChartsData = generateMountainChartsData(rawData?.spTable?.data?.tableData, selectedUnitNumber)
  const avgPriceExplanations = [rawData?.spGraph?.data?.averagePrice1Explanation, rawData?.spGraph?.data?.averagePrice2Explanation, rawData?.spGraph?.data?.averagePrice3Explanation, rawData?.spGraph?.data?.averagePrice4Explanation]
  const tableExplanations = [rawData?.monthly?.tableExplanation1, rawData?.monthly?.tableExplanation2, rawData?.monthly?.tableExplanation3, rawData?.monthly?.tableExplanation4]
  const parityGraphExplanations = [rawData?.monthly?.parityGraphExplanation1, rawData?.monthly?.parityGraphExplanation2, rawData?.monthly?.parityGraphExplanation3, rawData?.monthly?.parityGraphExplanation4]

  const hsTotalValFirmNumExplanation = rawData?.spTable?.data?.hsTotalValFirmNumExplanation
  const avgPriceHsTotalValExplanations = [rawData?.spTable?.data?.avgPriceHsTotalValExplanation1, rawData?.spTable?.data?.avgPriceHsTotalValExplanation2, rawData?.spTable?.data?.avgPriceHsTotalValExplanation3, rawData?.spTable?.data?.avgPriceHsTotalValExplanation4]
  const avgPriceFirmNumExplanations = [rawData?.spTable?.data?.avgPriceFirmNumExplanation1, rawData?.spTable?.data?.avgPriceFirmNumExplanation2, rawData?.spTable?.data?.avgPriceFirmNumExplanation3, rawData?.spTable?.data?.avgPriceFirmNumExplanation4]
  const hsCountHsTotalValExplanation = rawData?.spTable?.data?.hsCountHsTotalValExplanation
  const avgPriceHsTotalValPercentageExplanations = [rawData?.spTable?.data?.avgPriceHsTotalValPercentageExplanation1, rawData?.spTable?.data?.avgPriceHsTotalValPercentageExplanation2, rawData?.spTable?.data?.avgPriceHsTotalValPercentageExplanation3, rawData?.spTable?.data?.avgPriceHsTotalValPercentageExplanation4]

  const allExplanations = {
    avgPriceExplanations,
    tableExplanations,
    parityGraphExplanations,
    hsTotalValFirmNumExplanation,
    avgPriceHsTotalValExplanations,
    avgPriceFirmNumExplanations,
    hsCountHsTotalValExplanation,
    avgPriceHsTotalValPercentageExplanations
  }
  if (data?.length) {
    const annualAverageSectionData = generatePriceAnalysisMapBarSectionData(data, countries, selectedUnit, rawData?.spGraph?.data?.company_count_per_country)
    const annualAverageWorldSectionData = generatePriceAnalysisWorldMapBarSectionData(rawData?.spSummary?.data?.summaryData, countries, selectedUnit, selectedUnitNumber, rawData?.spGraph?.data?.company_count_per_country)
    set(annualAverageSectionData.map, annualAverageSectionData.bar, annualAverageWorldSectionData.map,
      annualAverageWorldSectionData.bar, averageMonthlySectionData.line, averageMonthlySectionData.table,
      mountainChartsData.hsTotalMountain, mountainChartsData.hsCountMountain, mountainChartsData.avgPriceMountain,
      mountainChartsData.avgCurrencyMountain, mountainChartsData.avgInterestMountain, allExplanations
    )
  }
}

const generateAndSetMarketOverviewSectionData = (rawData, countries, set) => {
  const valueData = generateMarketOverviewDataByParamName(rawData?.comtradeMarketOverview?.value, countries, false)
  const quantityData = generateMarketOverviewDataByParamName(rawData?.comtradeMarketOverview?.quantity, countries, false)
  const averagePriceData = generateMarketOverviewDataByParamName(rawData?.comtradeMarketOverview?.averagePrice, countries, true)
  const data = { value: valueData, quantity: quantityData, averagePrice: averagePriceData }
  set(data)
}

const generateBarChartDataForMarketChanges = (datapoint, countries) => {
  const yearlyHsSums = datapoint?.yearlyHsSums ?? []

  const stackedData = datapoint?.data?.map(item => ({
    countryCode: McPageHelpers.findCountryName(item.countryCode, countries),
    last12Months: Number(item.last12Months.toFixed(3)),
    previous12Months: Number(item.previous12Months.toFixed(3)),
    last12MonthsTxt: McPageHelpers.numToLocaleString(item.last12Months / 100, 'percent', null, 1, 1),
    previous12MonthsTxt: McPageHelpers.numToLocaleString(item.previous12Months / 100, 'percent', null, 1, 1),
    last12monthsTooltip: McPageHelpers.getYearAndMonth(Object.keys(yearlyHsSums)[1]),
    previous12MonthsTooltip: McPageHelpers.getYearAndMonth(Object.keys(yearlyHsSums)[0]),
    last12MonthsName: yearlyHsSums ? Object.keys(yearlyHsSums)[1] : i18n.t('market_share_last12Months'),
    previous12MonthsName: yearlyHsSums ? Object.keys(yearlyHsSums)[0] : i18n.t('market_share_previous12Months')
  })).sort((a, b) => (a?.previous12Months) - (b?.previous12Months))

  return {
    stackedData
  }
}

const generateStackedChartDataForMarketChanges = (datapoint, countries) => {
  const countryCodes = new Set()
  const stackedData = datapoint?.map(item => {
    const calculate = item.last12Months - item.previous12Months
    const tmpPercentage = {
      countryCode: McPageHelpers.findCountryName(item.countryCode, countries),
      sortItem: item.previous12Months,
      total: 0
    }

    for (const percentage in item.countryPercentages) {
      countryCodes.add(percentage)
      tmpPercentage[percentage] = Number((calculate * (item.countryPercentages[percentage] / 100)).toFixed(3))

      tmpPercentage.total += Number((calculate * (item.countryPercentages[percentage] / 100)))
      tmpPercentage.totalTooltip = McPageHelpers.numToLocaleString(Math.abs(tmpPercentage.total) / 100, 'percent', null, 1, 1)
      const tooltipParamName = 'tooltip-' + percentage
      const tooltipNumberParamName = 'tooltipN-' + percentage

      tmpPercentage[tooltipParamName] = McPageHelpers.numToLocaleString(Math.abs(tmpPercentage[percentage]) / 100, 'percent', null, 1, 1)
      tmpPercentage[tooltipNumberParamName] = tmpPercentage[percentage]
    }
    return tmpPercentage
  }).sort((a, b) => (a.sortItem) - (b.sortItem))

  for (let i = 0; i < stackedData?.length; i++) {
    const item = stackedData[i]
    for (const key in item) {
      if (key.startsWith('tooltipN-')) {
        const countryKey = key.substr(9)
        if (item[key] !== undefined && item[key] !== 0) {
          item[`divided-${countryKey}`] = McPageHelpers.numToLocaleString(item[key].toFixed(1) / (item.total).toFixed(1), 'percent', false, 1, 1)
        } else {
          item[`divided-${countryKey}`] = 0
        }
      }
    }
  }

  return {
    stackedData, countryCodes
  }
}

const generateSeriesForMarketChanges = (map, timeTags, searchedCountryCode, countries, excluded = false, quantity = false) => {
  const handleInfinityOrNaN = (value) => {
    if (value === 'Infinity' || value === 'NaN') {
      return 100
    }
    return value * 100
  }
  const generatedValue = map?.filter(item => [searchedCountryCode, CONSTANTS.WORLD_COUNTRY_CODE]?.includes(item.countryCode) === excluded).map((item) => timeTags?.map((value, key) => {
    const countryData = map?.filter(el => el.countryCode === item.countryCode)[0]
    return ({
      date: generateFullTime(value),
      _date: McPageHelpers.generateDateText(value),
      monthly: McPageHelpers.numToLocaleString(countryData?.monthlyChange[key], 'percent', null, 1, 1),
      yearly: McPageHelpers.numToLocaleString(countryData?.yearlyChange[key], 'percent', null, 1, 1),
      raw: McPageHelpers.numToLocaleString(countryData?.rawData[key], 'decimal', null, 1, 1, '/', 1000),
      monthlyValue: handleInfinityOrNaN(countryData?.monthlyChange[key]),
      yearlyValue: handleInfinityOrNaN(countryData?.yearlyChange[key]),
      rawValue: Math.round(McPageHelpers.numDivisionByThousand(countryData?.rawData[key])),
      _country: item.countryCode,
      _countryName: countries?.filter(country => country.countryCode === item.countryCode)[0]?.countryName ? countries?.filter(country => country.countryCode === item.countryCode)[0]?.countryName : item.countryCode === CONSTANTS.WORLD_COUNTRY_CODE ? i18n.t('world') : item.countryCode
    })
  }))
  return generatedValue
}

const generateAndSetMarketChangesSectionData = (rawData, countries, searchedCountryCode, set) => {
  const data = {
    clustered: generateBarChartDataForMarketChanges(rawData?.marketChanges, countries),
    stacked: generateStackedChartDataForMarketChanges(rawData?.marketChanges?.data, countries),
    table: rawData?.marketChanges?.data?.reverse(),
    series: {
      value: generateSeriesForMarketChanges(rawData?.comtradeMarketChange?.valueSeriesList, rawData?.comtradeMarketChange?.timeTags, searchedCountryCode, countries),
      quantity: generateSeriesForMarketChanges(rawData?.comtradeMarketChange?.quantitySeriesList, rawData?.comtradeMarketChange?.timeTags, searchedCountryCode, countries),
      excludedValue: generateSeriesForMarketChanges(rawData?.comtradeMarketChange?.valueSeriesList, rawData?.comtradeMarketChange?.timeTags, searchedCountryCode, countries, true),
      excludedQuantity: generateSeriesForMarketChanges(rawData?.comtradeMarketChange?.quantitySeriesList, rawData?.comtradeMarketChange?.timeTags, searchedCountryCode, countries, true, 'quantity')
    },
    explanations: {
      marketShareChangeExplanation: rawData?.marketChanges?.marketShareChangeExplanation, // G-PD-01
      marketShareChangeDetailsExplanation: rawData?.marketChanges?.marketShareChangeDetailsExplanation, // T-PD-01
      importerMonthlyValueExplanation: rawData?.comtradeMarketChange?.importerMonthlyValueExplanation, // G-PD-03
      importerValueChangeExplanation: rawData?.comtradeMarketChange?.importerValueChangeExplanation, // G-PD-04
      worldMonthlyImportValueExplanation: rawData?.comtradeMarketChange?.worldMonthlyImportValueExplanation, // G-PD-05
      worldImportValueChangeExplanation: rawData?.comtradeMarketChange?.worldImportValueChangeExplanation, // G-PD-06
      importerMonthlyQuantityExplanation: rawData?.comtradeMarketChange?.importerMonthlyQuantityExplanation, // G-PD-07
      importerQuantityChangeExplanation: rawData?.comtradeMarketChange?.importerQuantityChangeExplanation, // G-PD-08
      worldMonthlyImportQuantityExplanation: rawData?.comtradeMarketChange?.worldMonthlyImportQuantityExplanation, // G-PD-09
      worldImportQuantityChangeExplanation: rawData?.comtradeMarketChange?.worldImportQuantityChangeExplanation, // G-PD-10
      importerMonthlyTop5ValueExplanation: rawData?.comtradeMarketChange?.importerMonthlyTop5ValueExplanation, // G-PD-11
      importerTop5ValueChangeExplanation: rawData?.comtradeMarketChange?.importerTop5ValueChangeExplanation, // G-PD-12
      importerMonthlyTop5QuantityExplanation: rawData?.comtradeMarketChange?.importerMonthlyTop5QuantityExplanation, // G-PD-13
      importerTop5QuantityChangeExplanation: rawData?.comtradeMarketChange?.importerTop5QuantityChangeExplanation // G-PD-14
    }
  }
  set(data)
}

const mapComtradeSummaryData = (data) => {
  const handleValue = (value) => {
    if (value === 'NaN') return '-'
    if (value === 'Infinity') return '∞'
    return value
  }

  return data?.map(item => ({
    cumulativePercentValue: handleValue(item.cumulative_percent_value),
    cumulativePercentQuantity: handleValue(item.cumulative_percent_quantity),
    concentration: handleValue(item.concentration),
    growthOneYearQuantity: handleValue(item.growth_1year_quantity),
    growthOneYearValue: handleValue(item.growth_1year_value),
    growthThreeYearQuantity: handleValue(item.growth_3year_quantity),
    growthThreeYearValue: handleValue(item.growth_3year_value),
    indexOnQuantity: handleValue(item.index_on_quantity),
    indexOnValue: handleValue(item.index_on_value),
    partnerCode: handleValue(item.partner_code ?? item.reporter_code),
    percentQuantity: handleValue(item.percent_quantity),
    percentValue: handleValue(item.percent_value),
    totalValue: handleValue(item.total_value),
    totalWeight: handleValue(item.total_weight),
    tradeBalance: handleValue(item.trade_balance),
    unitPrice: handleValue(item.unit_price),
    worldExportOrder: handleValue(item.world_export_order),
    worldExportPercentQuantity: handleValue(item.world_export_percent_quantity),
    worldExportPercentValue: handleValue(item.world_export_percent_value),
    worldImportOrder: handleValue(item.world_import_order),
    worldImportPercentQuantity: handleValue(item.world_import_percent_quantity),
    worldImportPercentValue: handleValue(item.world_import_percent_value),
    xXportIncrease: handleValue(item.xxport_increase)
  }))
}

const generateAndSetSummaryTablesSectionData = (rawDataComtradeSummary, set) => {
  const importerYearly = mapComtradeSummaryData(rawDataComtradeSummary?.importerYearly)
  const importerMonthly = mapComtradeSummaryData(rawDataComtradeSummary?.importerMonthly)
  const exporterYearly = mapComtradeSummaryData(rawDataComtradeSummary?.exporterYearly)
  const exporterMonthly = mapComtradeSummaryData(rawDataComtradeSummary?.exporterMonthly)
  const worldImporterYearly = mapComtradeSummaryData(rawDataComtradeSummary?.worldImportYearly)
  const worldImporterMonthly = mapComtradeSummaryData(rawDataComtradeSummary?.worldImportMonthly)
  const worldExporterYearly = mapComtradeSummaryData(rawDataComtradeSummary?.worldExportYearly)
  const worldExporterMonthly = mapComtradeSummaryData(rawDataComtradeSummary?.worldExportMonthly)

  const data = {
    import: {
      yearly: importerYearly,
      monthly: importerMonthly,
      year: rawDataComtradeSummary?.importerYear,
      month: rawDataComtradeSummary?.importerYearMonth,
      yearlyExplanation: rawDataComtradeSummary?.importerYearlyExplanation,
      monthlyExplanation: rawDataComtradeSummary?.importerMonthlyExplanation
    },
    export: {
      yearly: exporterYearly,
      monthly: exporterMonthly,
      year: rawDataComtradeSummary?.exporterYear,
      month: rawDataComtradeSummary?.exporterYearMonth,
      yearlyExplanation: rawDataComtradeSummary?.exporterYearlyExplanation,
      monthlyExplanation: rawDataComtradeSummary?.exporterMonthlyExplanation
    },
    world: {
      import: {
        yearly: worldImporterYearly,
        monthly: worldImporterMonthly,
        year: rawDataComtradeSummary?.worldImporterYear,
        month: rawDataComtradeSummary?.worldImporterYearMonth,
        yearlyExplanation: rawDataComtradeSummary?.worldImportYearlyExplanation,
        monthlyExplanation: rawDataComtradeSummary?.worldImportMonthlyExplanation
      },
      export: {
        yearly: worldExporterYearly,
        monthly: worldExporterMonthly,
        year: rawDataComtradeSummary?.worldExporterYear,
        month: rawDataComtradeSummary?.worldExporterYearMonth,
        yearlyExplanation: rawDataComtradeSummary?.worldExportYearlyExplanation,
        monthlyExplanation: rawDataComtradeSummary?.worldExportMonthlyExplanation
      }
    },
    change: {
      // change datasından beslenen componentler rawDataComtradeSummary kullanıyor
      year: rawDataComtradeSummary?.yearlyChange?.currentTimeTag,
      month: rawDataComtradeSummary?.monthlyChange?.currentTimeTag
    }
  }
  set(data)
  return data
}

const transformDuties = (duties, importerCountryCode) => {
  const result = []
  duties?.forEach(item => {
    const dutiesObj = []
    const defaultColumns = new Set()
    const defaultObj = {
      title: i18n.t('tax_rates') + ' -  2023',
      data: [],
      columns: [],
      keyExpr: 'id',
      parentIdExpr: 'parentId',
      expandedKeys: [1]
    }
    if (importerCountryCode === 'CL') DutiesTransformer.CL(item?.dutiesData, defaultObj, defaultColumns, dutiesObj)
    if (importerCountryCode === 'US') DutiesTransformer.US(item?.dutiesData, defaultObj, dutiesObj)
    if (importerCountryCode === 'QA') DutiesTransformer.QA(item?.dutiesData, defaultObj, defaultColumns, dutiesObj)
    if (importerCountryCode === 'KW') DutiesTransformer.KW(item?.dutiesData, defaultObj, defaultColumns, dutiesObj)
    if (importerCountryCode === 'JO') DutiesTransformer.JO(item?.dutiesData, defaultObj, defaultColumns, dutiesObj)
    if (importerCountryCode === 'SA') DutiesTransformer.SA(item?.dutiesData, defaultObj, defaultColumns, dutiesObj)
    if (importerCountryCode === 'CA') DutiesTransformer.CA(item?.dutiesData, defaultObj, defaultColumns, dutiesObj)
    if (importerCountryCode === 'LB') DutiesTransformer.LB(item?.dutiesData, defaultObj, defaultColumns, dutiesObj)
    if (EU_COUNTRY_CODES.includes(importerCountryCode)) DutiesTransformer.EU(item?.dutiesData, defaultObj, dutiesObj)
    if (SACU_COUNTRY_CODES.includes(importerCountryCode)) DutiesTransformer.SACU(item?.dutiesData, defaultObj, defaultColumns, dutiesObj)
    if (ECOWAS_COUNTRY_CODES.includes(importerCountryCode)) DutiesTransformer.ECOWAS(item?.dutiesData, defaultObj, dutiesObj)
    result.push({ dutiesObj, explanation: item?.explanation })
  })
  return result
}

const generateAndSetCustomsDutiesSectionData = (rawData, importerCountryCode, set) => {
  const data = transformDuties(rawData?.duties, importerCountryCode)
  set(data)
}

const McDataProcessor = {
  generateAndSetImporterSectionData,
  generateAndSetPotentialSectionData,
  generateAndSetProductAnalysisSectionData,
  generateAndSetPriceAnalysisSectionData,
  generateAndSetMarketOverviewSectionData,
  generateAndSetMarketChangesSectionData,
  generateAndSetCustomsDutiesSectionData,
  generateAndSetSummaryTablesSectionData
}

export default McDataProcessor
