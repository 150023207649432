import { UserTypes } from './constants'

export const getBooleanUserRolesByUser = (user) => {
  const userRoles = user?.roles

  const B2B_PERSONNEL = !!userRoles?.includes(UserTypes.B2B_PERSONNEL)
  const B2B_USER = !!userRoles?.includes(UserTypes.B2B_USER)

  return {
    B2B_PERSONNEL,
    B2B_USER,
    B2B: B2B_PERSONNEL || B2B_USER// imaginal type
  }
}

export const extractHtmlContent = (htmlString) => {
  return new DOMParser()
    .parseFromString(htmlString, 'text/html')
    .documentElement.textContent
}
