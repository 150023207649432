import McDataServices from '../services/McDataServices'
import * as am5 from '@amcharts/amcharts5'

export const MC_KEY = 'bWFya2V0Y2FsY3VsdXM='
export const MC_KEY_VALID = 'dHJ1ZQ=='

// url for production
export let url = ''
if (process.env.NODE_ENV === 'development') {
  url = ''
} else {
  url = window.location.host.split('/')[1]
  if (url) {
    url = `/${window.location.host.split('/')[1]}`
  } else url = process.env.PUBLIC_URL /// ADD YOUR CPANEL SUB-URL
}

// Logs out user
export const handleSignout = () => {
  McDataServices.logout().then().finally(() => {
    localStorage.setItem('username', '')
    window.history.pushState(null, null, '/')
    window.location.reload()
  })
}

// Function to validate and return errors for a form
export const checkForm = (formData) => {
  const errorState = {}
  Object.keys(formData).forEach((item) => {
    if (formData[item] === null || formData[item] === '') {
      errorState[item] = 'This field is required'
    }
  })
  return errorState
}

// Function that returns the first or first two letters from a name
export const findUpper = (string) => {
  const extractedString = []

  for (let i = 0; i < string?.length; i++) {
    if (string.charAt(i) === string.charAt(i).toUpperCase() && string.charAt(i) !== ' ') {
      extractedString.push(string.charAt(i))
    }
  }
  if (extractedString?.length > 1) {
    return extractedString[0] + extractedString[1]
  } else {
    return extractedString[0]
  }
}

// Function that calculates the from current date
export const setDeadline = (days) => {
  const todayDate = new Date()
  const newDate = new Date(todayDate)
  newDate.setDate(newDate.getDate() + days)
  return newDate
}

// Function to structure date ex : Jun 4, 2011;
export const getDateStructured = (date) => {
  const d = date.getDate()
  const m = date.getMonth()
  const y = date.getFullYear()
  const final = monthNames[m] + ' ' + d + ', ' + y
  return final
}

// Function to structure date ex: YYYY-MM-DD
export const setDateForPicker = (rdate) => {
  let d = rdate.getDate()
  d < 10 && (d = '0' + d)
  let m = rdate.getMonth() + 1
  m < 10 && (m = '0' + m)
  const y = rdate.getFullYear()
  rdate = y + '-' + m + '-' + d

  return rdate
}

// Set deadlines for projects
export const setDeadlineDays = (deadline) => {
  const currentDate = new Date()
  const difference = deadline.getTime() - currentDate.getTime()
  const days = Math.ceil(difference / (1000 * 3600 * 24))
  return days
}

// Date formatter function
export const dateFormatterAlt = (date, reverse) => {
  const d = date.getDate()
  const m = date.getMonth() + 1
  const y = date.getFullYear()
  reverse ? (date = m + ' - ' + d + ' - ' + y) : (date = y + ' - ' + d + ' - ' + m)
  return date
}

// Date formatter function
export const dateFormatter = (date, reverse) => {
  const dateformat = date.split('-')
  // var date = dateformat[1]+"-"+dateformat[2]+"-"+dateformat[0];
  reverse
    ? (date = dateformat[2] + '-' + dateformat[0] + '-' + dateformat[1])
    : (date = dateformat[1] + '-' + dateformat[2] + '-' + dateformat[0])

  return date
}

// todays Date
export const todaysDate = new Date()

// current Time
export const currentTime = () => {
  let hours = todaysDate.getHours()
  let minutes = todaysDate.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours || 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  const strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

// Percentage calculation
export const calcPercentage = (str1, str2) => {
  let result = Number(str2) / Number(str1)
  result = result * 100
  return Math.floor(result)
}

export const truncate = (str, n) => {
  return str?.length > n ? str.substr(0, n - 1) + ' ' + truncate(str.substr(n - 1, str?.length), n) : str
}

// returns upload url
export const getUploadParams = () => {
  return { url: 'https://httpbin.org/post' }
}

export const bulkActionOptions = [
  { value: 'suspend', label: 'Suspend User' },
  { value: 'delete', label: 'Delete User' }
]

// Converts KB to MB
export const bytesToMegaBytes = (bytes) => {
  const result = bytes / (1024 * 1024)
  return result.toFixed(2)
}

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const addDays = (date, days) => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

// Returns Currency based value for invest panel
export const returnCurrency = (currency, data, upperCase) => {
  if (currency === 'usd') {
    return { value: data.usd.toFixed(2), label: upperCase ? 'USD' : '$' }
  } else if (currency === 'eur') {
    return { value: data.euro.toFixed(2), label: upperCase ? 'EUR' : 'euro' }
  } else if (currency === 'btc') {
    return { value: data.BTC.toFixed(6), label: 'BTC' }
  } else {
    return { value: data.ETH.toFixed(2), label: 'ETH' }
  }
}

// Returns levels
export const returnLevel = (currency, data) => {
  if (currency === 'usd') {
    return data.usd
  } else if (currency === 'eur') {
    return data.euro
  } else if (currency === 'btc') {
    const amount = data.BTC.map((item) => {
      return item.toFixed(6)
    })
    return amount
  } else {
    return data.ETH
  }
}

export const LANGUAGES = {
  TURKISH: 'tr',
  ENGLISH: 'en'
}

export const LOCALE = {
  EN_US: 'en-US',
  TR_TR: 'tr-TR'
}

export const setLanguageInURL = (lang, setLocalStorage = true) => {
  const predefinedSearchParams = new URL(window.location.href).searchParams

  const validLanguages = ['en', 'tr']

  const currentPath = window.location.pathname
  const pathSegments = currentPath.split('/')
  const firstSegment = pathSegments[1]

  if ((firstSegment === '' || !validLanguages.includes(firstSegment)) && setLocalStorage) {
    pathSegments.splice(1, 0, lang)
  } else if (firstSegment !== lang && validLanguages.includes(firstSegment) && setLocalStorage) {
    localStorage.setItem('lang', firstSegment)
  }

  if (!setLocalStorage) {
    pathSegments.splice(1, 1, lang)
  }

  const newPath = pathSegments.join('/')
  let normalizedNewPath = newPath.endsWith('/') ? newPath.slice(0, -1) : newPath
  if (predefinedSearchParams.size) normalizedNewPath = normalizedNewPath + '?' + predefinedSearchParams.toString()

  window.history.replaceState(null, null, `${process.env.PUBLIC_URL}${normalizedNewPath}`)
}

export const changeSearchParams = (paramName, value) => {
  const predefinedSearchParams = new URL(window.location.href).searchParams
  const path = new URL(window.location.href).pathname
  if (predefinedSearchParams.get(paramName) !== value) {
    predefinedSearchParams.set(paramName, value)
    const URL = `${process.env.PUBLIC_URL + path + '?' + predefinedSearchParams.toString()}`
    window.history.pushState(URL, 'search', URL)
  }
}

export const am5AddLicense = () => {
  am5.addLicense('AM5C358457823')
  am5.addLicense('AM5M358457823')
}

export const camelToFlat = (camel) => {
  const camelCase = camel.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ')

  let flat = ''

  camelCase.forEach(word => {
    flat = flat + word.charAt(0).toUpperCase() + word.slice(1) + ' '
  })
  return flat
}
