import React, { useEffect, useState } from 'react'
import menu from './MenuData'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useGlobalStoreData } from '../../store/globalStore'
import { useSDSsearchParams, useSDSsearchedDataCoverage, useSDSimaginalUrlSearchParams } from '../../store/searchDataStore'
import { Col, Row, Spinner } from 'reactstrap'
import { MarketCalculusLinkCard } from '../../components/preview/Preview'
import { Icon } from '../../components/Component'
import useEndpointStatus from '../../store/endpointStatus'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'
import { DUTIES_COUNTRY_CODES } from '../../utils/Constants'

const MenuHeader = ({ item }) => {
  return (
    <li className="nk-menu-heading">
      <h6 className="overline-title text-primary">{item}</h6>
    </li>
  )
}

const MenuStatusIcon = ({ loading }) => {
  const [successVisible, setSuccessVisible] = useState(true)

  const arrangeSuccessVisibility = (loadingStatus) => {
    if (loadingStatus === 'success') {
      setTimeout(() => {
        setSuccessVisible(false)
      }, 2000)
    } else {
      setSuccessVisible(true)
    }
  }

  useEffect(() => {
    arrangeSuccessVisibility(loading)
  }, [loading])

  return (
    <>
      {successVisible && loading === 'success' && <Icon name="check-circle-fill" className="menu-spinner" style={{ color: '#16a085' }}/>}
      {loading === 'error' && <Icon name="cross-circle menu-spinner" style={{ color: 'red' }}/>}
      {loading === 'caution' && <Icon name="alert-circle menu-spinner" style={{ color: '16a085' }}/>}
      {loading === 'loading' && <Spinner className="menu-spinner" color="primary" size="sm"/>}
    </>
  )
}

const MenuItem = ({ item, headActive, currentLanguage, dataCoverage, countryToExport }) => {
  const { subMenu, subPanel, text, link, newTab, badge, header, disabled, loading } = item
  const { t } = useTranslation()
  const urlSearchParams = useSDSimaginalUrlSearchParams()

  if (header) {
    return <MenuHeader item={header}></MenuHeader>
  } else {
    return (
      <li
        className={`nk-menu-item ${subMenu ? 'has-sub' : ''} ${process.env.PUBLIC_URL + '/' + currentLanguage + link === window.location.pathname ? 'active current-page' : ''} ${disabled && !dataCoverage && text === 'menu.market_changes' ? 'menu-disabled' : ''} ${disabled && !DUTIES_COUNTRY_CODES.includes(countryToExport) && text === 'menu.duties' ? 'menu-disabled' : ''} ${headActive ? 'active current-page' : ''}`}
        title={disabled && countryToExport !== 'US' && text === 'menu.duties' ? t('active_for_duties') : t(text)}
      >
        {newTab
          ? (
          <NavLink
            className="nk-menu-link"
            target="_blank"
            rel="noopener noreferrer"
            to={`${process.env.PUBLIC_URL + '/' + currentLanguage + link + '?' + urlSearchParams}`}
          >
            <span className="nk-menu-text">{t(text)}</span>
            {subPanel && <span className="nk-menu-badge">Hot</span>}
          </NavLink>
            )
          : subMenu
            ? (
          <React.Fragment>
            <a
              href="#toggle"
              className="nk-menu-link nk-menu-toggle"
              onClick={(ev) => {
                ev.preventDefault()
              }}
            >
              <span className="nk-menu-text">{t(text)}</span>
            </a>
            <MenuSub subMenu={subMenu}/>
          </React.Fragment>
              )
            : (
          <NavLink className="nk-menu-link "
                   to={process.env.PUBLIC_URL + '/' + currentLanguage + link + '?' + urlSearchParams}>
            <span className="nk-menu-text">{t(text)}</span>
            {badge && <span className="nk-menu-badge">{badge}</span>}
          </NavLink>
              )}

          <MenuStatusIcon loading={loading}/>
      </li>
    )
  }
}

const MenuSub = ({ subMenu }) => {
  return (
    <ul className="nk-menu-sub">
      {subMenu.map((sub, index) => (
        <MenuItem item={sub} key={index}/>
      ))}
    </ul>
  )
}

const checkMenuUrl = (data, currentLanguage) => {
  for (const node of data.subMenu) {
    if (process.env.PUBLIC_URL + '/' + currentLanguage + node.link === window.location.pathname) {
      return node
    } else {
      const newNode = node.subMenu ? checkMenuUrl(node, currentLanguage) : undefined
      if (newNode) return newNode
    }
  }
}

const findActiveHead = (currentLanguage) => {
  let found
  menu.forEach((item) => {
    const finding = item.subMenu?.find((s) => process.env.PUBLIC_URL + '/' + currentLanguage + s.link === window.location.pathname)

    if (finding) {
      found = item
    } else {
      item.subMenu?.forEach((p) => {
        if (p.subMenu) {
          const finding = checkMenuUrl(p, currentLanguage)
          if (finding) {
            found = item
          }
        }
      })
    }
  })
  return found
}

const Menu = ({ userRoles }) => {
  const [head, setHead] = useState('Dashboards')
  const [menus, setMenus] = useState(menu)
  const languageGSD = useGlobalStoreData.language()

  const searchParams = useSDSsearchParams()
  const searchedDataCoverage = useSDSsearchedDataCoverage()

  const endpointStatus = useEndpointStatus()
  const findingActiveHead = findActiveHead(languageGSD)

  useEffect(() => {
    if (findingActiveHead) {
      setHead(findingActiveHead.text)
    }
  }, [window.location.pathname])

  useEffect(() => {
    const updatedMenus = menus.map(menu => {
      const hasRequested = menu.endpoints.some(endpoint => endpointStatus[endpoint] === McPageHelpers.REQUEST_STATUSES.REQUESTED)
      const hasError = menu.endpoints.some(endpoint => endpointStatus[endpoint] === McPageHelpers.REQUEST_STATUSES.ERROR)

      let loading = ''

      if (hasRequested) {
        loading = 'loading'
      } else if (menu.endpoints.every(endpoint => endpointStatus[endpoint] === McPageHelpers.REQUEST_STATUSES.SUCCESS)) {
        loading = 'success'
      } else if (menu.endpoints.every(endpoint => endpointStatus[endpoint] === McPageHelpers.REQUEST_STATUSES.ERROR)) {
        loading = 'error'
      } else if (hasError) {
        loading = 'caution'
      }

      return { ...menu, loading }
    })

    setMenus(updatedMenus)
  }, [endpointStatus])

  return (
    <ul className="nk-menu nk-menu-main ui-s2">
      {menus.map((item, index) =>
        <MenuItem
          key={index}
          item={item}
          headActive={item.text === head}
          currentLanguage={languageGSD}
          dataCoverage={searchedDataCoverage}
          countryToExport={searchParams.countryToExport}
        />
      )}
      {userRoles?.B2B &&
        <MenuItem
          item={{
            text: 'menu.b2bMail',
            link: `/mailing${userRoles?.B2B_PERSONNEL ? '/users' : ''}`,
            endpoints: []
          }}
          headActive={head === 'menu.b2bMail'}
          currentLanguage={languageGSD}
          dataCoverage={searchedDataCoverage}
          countryToExport={searchParams.countryToExport}
        />
      }
    </ul>
  )
}

export const DashboardMenuCards = () => {
  const { t } = useTranslation()
  const languageGSD = useGlobalStoreData.language()

  const urlSearchParams = useSDSimaginalUrlSearchParams()
  const searchedDataCoverage = useSDSsearchedDataCoverage()

  return (
    <Row>
      {menu.map((item, index) =>
        item.link !== '' && <Col key={'menu_' + index}>
            <MarketCalculusLinkCard
              disabled={item.disabled && !searchedDataCoverage}
              link={process.env.PUBLIC_URL + '/' + languageGSD + item.link + '?' + urlSearchParams}>
              <Icon name="reports center" style={{ fontSize: '3rem', display: 'block', marginBottom: '1rem' }}/>
              <h5>{t(item.text)}</h5>
            </MarketCalculusLinkCard>
          </Col>
      )}
    </Row>
  )
}

export default Menu
