import React, { useLayoutEffect, useRef, useState } from 'react'
import * as am5 from '@amcharts/amcharts5'
import { localizationAMChart } from '../../utils/AMChartsSettings'
import * as am5map from '@amcharts/amcharts5/map'
import am5geodataWorldLow from '@amcharts/amcharts5-geodata/worldLow'
import { t } from 'i18next'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'
import { useDSglobalParams } from '../../store/dataStore'
import BadgeCode from '../badge/BadgeCode'

const HeatMap = ({
  chartId,
  chartData,
  code,
  height,
  charTitle,
  filterByCountry,
  filterByCountryList,
  reset,
  selectedChartId,
  totalImporterCompanyCount,
  memberCountryCode,
  searchCountry,
  isShowTooltip,
  ...props
}) => {
  const seriesRef = useRef(null)
  const templateField = useRef(null)
  const templateFieldSearch = useRef(null)
  const [mapId, setMapId] = useState(null)
  const globalParams = useDSglobalParams()
  const rootRef = useRef(null)

  useLayoutEffect(() => {
    if (filterByCountry) {
      if (selectedChartId !== mapId && mapId) {
        seriesRef?.current?.data.setAll(chartData)
      }
    }
  }, [selectedChartId])

  useLayoutEffect(() => {
    const root = am5.Root.new(chartId)
    localizationAMChart(root)

    const HEAT_MAP_COLORS = {
      START: am5.color(0x2df7f7),
      END: am5.color(0x112cf5),
      STROKE: am5.color(0x808080),
      NEUTRAL: am5.color(0xd3d3d3),
      OPACITY: 0.5
    }

    const chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: 'translateX',
        panY: 'translateY',
        projection: am5map.geoMercator(),
        layout: root.horizontalLayout,
        exportable: true
      })
    )

    let polygonSeriesTemplateFields
    let polygonSeriestemplateFieldSearch

    if (memberCountryCode) {
      polygonSeriesTemplateFields = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodataWorldLow,
          include: [memberCountryCode],
          valueField: 'value',
          calculateAggregates: true,
          fill: am5.color(0xFFA500),
          stroke: HEAT_MAP_COLORS.STROKE
        })
      )

      polygonSeriesTemplateFields.mapPolygons.template.setAll({
        templateField: 'polygonSettings',
        tooltipText: !isShowTooltip ? `[bold]${t('exporting_country')}\n[normal]${McPageHelpers.findCountryName(memberCountryCode)}` : `[bold]${t('exporting_country')}\n[normal]{tooltipTxt}`,
        interactive: true
      })

      polygonSeriesTemplateFields.mapPolygons.template.events.on('pointerover', function (ev) {
        heatLegend.showValue(ev.target.dataItem.dataContext.value)
      })
      templateField.current = polygonSeriesTemplateFields
    }
    if (searchCountry) {
      polygonSeriestemplateFieldSearch = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodataWorldLow,
          include: [searchCountry],
          valueField: 'value',
          calculateAggregates: true,
          fill: am5.color(0xFFA500),
          stroke: HEAT_MAP_COLORS.STROKE
        })
      )
      polygonSeriestemplateFieldSearch.mapPolygons.template.setAll({
        templateField: 'polygonSettings',
        tooltipText: !isShowTooltip ? `[bold]${t('importing_country')}\n[normal]${globalParams.countryName}` : `[bold]${t('importing_country')}\n[normal]{tooltipTxt}`,
        interactive: true
      })

      polygonSeriestemplateFieldSearch.mapPolygons.template.events.on('pointerover', function (ev) {
        heatLegend.showValue(ev.target.dataItem.dataContext.value)
      })
      templateFieldSearch.current = polygonSeriestemplateFieldSearch
    }

    const polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodataWorldLow,
        exclude: ['AQ', memberCountryCode, searchCountry],
        valueField: 'value',
        calculateAggregates: true,
        fill: HEAT_MAP_COLORS.NEUTRAL,
        stroke: HEAT_MAP_COLORS.STROKE
      })
    )

    polygonSeries.mapPolygons.template.adapters.add('tooltipText', function (text, ev) {
      if (!ev.dataItem.dataContext.tooltipTxt) {
        return McPageHelpers.findCountryName(ev.dataItem.dataContext.id) + ':\n' + t('no_data')
      }
      return '{tooltipTxt}'
    })

    if (chartData?.filter(item => item.value !== undefined)?.length > 1) {
      polygonSeries.mapPolygons.template.events.on('pointerover', function (ev) {
        heatLegend.showValue(ev.target.dataItem.dataContext.value)
      })
    }

    if (filterByCountry) {
      polygonSeries.mapPolygons.template.events.on('click', function (ev) {
        if (ev.target.dataItem.dataContext.value) {
          setMapId(ev.target?.root?.dom.id)
          filterByCountryList(ev.target.dataItem?.dataContext?.id, ev.target?.root?.dom.id)
        }
      })

      polygonSeries.mapPolygons.template.states.create('active', {
        stroke: am5.color(0x00c000),
        strokeWidth: 5
      })

      polygonSeries.mapPolygons.template.setAll({
        toggleKey: 'active'
      })

      let previousPolygon = null

      polygonSeries.mapPolygons.template.on('active', (active, target) => {
        if (previousPolygon && previousPolygon !== target && target?.dataItem?.dataContext?.value) {
          previousPolygon.set('active', false)
        } else if (!target?.dataItem?.dataContext?.value) {
          target.set('active', false)
        }
        if (target?.dataItem?.dataContext?.value) {
          previousPolygon = target
        }
      })
    }

    polygonSeries.set('heatRules', [{
      target: polygonSeries.mapPolygons.template,
      dataField: 'value',
      min: HEAT_MAP_COLORS.START,
      max: HEAT_MAP_COLORS.END,
      key: 'fill'
    }])

    const heatLegend = chart.children.push(
      am5.HeatLegend.new(root, {
        orientation: 'vertical',
        startColor: HEAT_MAP_COLORS.START,
        endColor: HEAT_MAP_COLORS.END,
        startText: polygonSeries.getPrivate('valueLow'),
        endText: polygonSeries.getPrivate('valueHigh'),
        stepCount: 1
      })
    )

    heatLegend.startLabel.setAll({
      fontSize: 12,
      fill: heatLegend.get('startColor')
    })

    heatLegend.endLabel.setAll({
      fontSize: 12,
      fill: heatLegend.get('endColor')
    })

    polygonSeries.events.on('datavalidated', function () {
      heatLegend.set('startValue', polygonSeries.getPrivate('valueLow'))
      heatLegend.set('endValue', polygonSeries.getPrivate('valueHigh'))
    })

    polygonSeries.mapPolygons.template.states.create('hover', {
      opacity: HEAT_MAP_COLORS.OPACITY
    })

    seriesRef.current = polygonSeries

    rootRef.current = root

    return () => {
      root.dispose()
    }
  }, [chartData])

  useLayoutEffect(() => {
    chartData && seriesRef.current?.data?.setAll(chartData.filter(item => item.id !== memberCountryCode || searchCountry))
    chartData && templateField.current?.data?.setAll(chartData.filter(item => item.id === memberCountryCode))
    chartData && templateFieldSearch.current?.data?.setAll(chartData.filter(item => item.id === searchCountry))
  }, [chartData])

  useLayoutEffect(() => {
    if (reset) {
      chartData && seriesRef.current.data.setAll(chartData)
    }
  }, [reset])

  return <>
    <div style={{ paddingBottom: '2rem', paddingLeft: '1rem' }} id={chartId + McPageHelpers.FOR_PDF_ID}>
      <div className={'card-title-group justify-center mb-3 mt-2'}>
        <div className="card-title">
          {code ? <BadgeCode color="info" text={code}/> : ''}
          <h6 className="title"
              style={{ whiteSpace: 'pre-line' }}>{charTitle} {totalImporterCompanyCount ? `(${McPageHelpers.numToLocaleString(totalImporterCompanyCount)})` : ''}</h6>
        </div>
      </div>

      <div id={chartId}
           style={{
             width: '100%',
             height: height ?? '30rem',
             cursor: `${filterByCountry && 'pointer'}`
           }}></div>
    </div>

  </>
}

export default HeatMap
