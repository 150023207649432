import React, { useEffect } from 'react'
import Content from '../layout/content/Content'
import Head from '../layout/head/Head'
import { useTranslation } from 'react-i18next'
import { useSDSfirstMenuItemLink, useSDSimaginalUrlSearchParams } from '../store/searchDataStore'
import { useGlobalStoreData } from '../store/globalStore'
import { useNavigate } from 'react-router-dom'

const Dashboard = ({ ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  // const userGSD = useGlobalStoreData.user()
  const languageGSD = useGlobalStoreData.language()

  const urlSearchParams = useSDSimaginalUrlSearchParams()
  const firstMenuItemLink = useSDSfirstMenuItemLink()

  const handleRestrictedAccess = () => {
    navigate(`${process.env.PUBLIC_URL}/${languageGSD}${firstMenuItemLink}${urlSearchParams ? '?' + urlSearchParams : ''}`)
  }

  useEffect(handleRestrictedAccess, [])

  return (
    <React.Fragment>
      <Head title={t('menu.dashboard')}/>
      <Content>
        {/* <BlockHead size="sm"> */}
        {/*  <BlockBetween> */}
        {/*    <BlockHeadContent> */}
        {/*      <BlockDes className="text-soft"> */}
        {/*        <p>{t('search_count')}: {userGSD?.activitySummary?.marketReportSearchCount}</p> */}
        {/*      </BlockDes> */}
        {/*    </BlockHeadContent> */}
        {/*    <BlockHeadContent className="text-end"> */}
        {/*      <BlockTitle page>{t('welcome')}, {userGSD.userName}!</BlockTitle> */}
        {/*      <BlockDes className="text-soft"> */}
        {/*        <p>{userGSD.activitySummary?.searchedHsCodes && t('searched_hs_codes') + ': ' + userGSD.activitySummary?.searchedHsCodes.join(', ')}</p> */}
        {/*      </BlockDes> */}
        {/*    </BlockHeadContent> */}
        {/*  </BlockBetween> */}
        {/* </BlockHead> */}
        {/* <DashboardMenuCards/> */}
      </Content>
    </React.Fragment>
  )
}

export default Dashboard
